<template>
  <Toast>
    <template #message="{ message }">
      <i :class="'p-toast-message-icon ti ti-' + (message.severity === 'error' ? 'xbox-x' : 'check')"></i>
      <div class="p-toast-message-text">
        <span class="p-toast-summary">{{ message.summary }}</span>
        <div class="p-toast-detail">{{ typeof message.detail === 'string' ? message.detail : message.detail.message }}</div>
        <div v-if="typeof message.detail === 'object'">
          <Button v-for="action in message.detail.actions" :label="action.label" rounded size="small" @click="action.callback" />
        </div>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast';

const toast = useToast();
</script>
<style>
</style>
