import {inject, InjectionKey} from "vue";
import {RPCService} from "./rpc.service";
import {AsyncSubject} from "rxjs";
import {Permission, PermissionContexts, SecurityContextType, ServiceRowExt_Service} from "./rpc/rpc-models";

export function injectGlobal<T>(key: InjectionKey<T> | string): T {
  let value = inject(key);
  if (value === undefined) {
    throw new Error("You requested " + key + " but the global was undefined; please register it in main.ts");
  }
  return value as T;
}

export class PermissionService {
  private services: Array<ServiceRowExt_Service> = null;
  private servicesPending: AsyncSubject<{ services: Array<ServiceRowExt_Service>, error: any }> = null;

  private contextPermissions: { [key: string]: Array<Permission> } = {};
  private contextPermissionsPending: { [key: string]: AsyncSubject<{ permissions: Array<Permission>, error: any }> } = {};

  private errorCount: number = 0;
  private nextRetry: number = 0;

  constructor(
    readonly rpc: RPCService,
  ) {
  }

  resetPermissions(): Promise<void> {
    const oldPermissions = this.contextPermissions;

    // Clear the local permissions caches
    this.contextPermissions = {};
    this.contextPermissionsPending = {};
    this.services = null;
    this.servicesPending = null;

    // to try and accomplish a seamless refresh, refill the previously pulled permissions with the current values before resolving the promise
    let contextPermissionPromises = Object.keys(oldPermissions).map((key) => {
      const segments = key.split("|");
      const type = segments[0] ? segments[0] as any : null;
      const contextId = segments.length > 1 ? Number(segments[1]) : null;
      return this.getContextPermissions(type, contextId);
    })

    // Pull the permissions for each context
    // Pull them in parallel, but wait for all of them to finish before resolving the promise
    // If any of them fail, reject the promise
    return Promise.all(contextPermissionPromises).then(() => {});
  }

  checkPermissions(check: PermissionCheck): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.checkPermissionsUnsafe(check).then(result => {
        resolve(result);
      }, (error) => {
        resolve(false);
      });
    });
  }

  private checkPermissionsUnsafe(check: PermissionCheck): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (check.type === "permission") {
        this.getContextPermissions(check.contextType, check.contextId).then((permissions) => {
          resolve(permissions.includes(check.permission));
        }, (error) => reject(error));
      } else if (check.type === "unary_operator") {
        this.checkPermissionsUnsafe(check.operand1).then(v1 => {
          resolve(check.operator === "!" ? !v1 : v1);
        }, (error) => reject(error));
      } else if (check.type === "binary_operator") {
        this.checkPermissionsUnsafe(check.operand1).then(v1 => {
          if (check.operator) {
            this.checkPermissionsUnsafe(check.operand2).then(v2 => {
              if (check.operator === "&") resolve(v1 && v2);
              else if (check.operator === "|") resolve(v1 || v2);
              else resolve(false);
            }, (error) => reject(error));
          } else {
            resolve(false);
          }
        }, (error) => reject(error));
      } else {
        resolve(false);
      }
    });
  }

  evalPermissions(checkExpression: string): Promise<boolean> {
    if (!checkExpression) return Promise.resolve(false);
    return this.checkPermissions(parsePermissions(checkExpression));
  }

  evalPermissionsUnsafe(checkExpression: string): Promise<boolean> {
    return this.checkPermissionsUnsafe(parsePermissions(checkExpression));
  }


  getContextPermissions(type?: SecurityContextType, contextId?: number): Promise<Array<Permission>> {
    return new Promise((resolve, reject) => {
      if (typeof type === 'undefined') type = null;
      if (typeof contextId === 'undefined') contextId = null;
      if (!type) type = null;

      const contextKey = (type ? String(type) : "") + (contextId ? "|" + contextId : "");
      const permissions = this.contextPermissions[contextKey];

      let pending: AsyncSubject<{ permissions: Array<Permission>, error: any }> = this.contextPermissionsPending[contextKey];
      if (permissions) {
        resolve(permissions);
      } else {
        if (this.nextRetry > new Date().getDate()) {
          resolve([]);
        } else {
          if (!pending) {
            pending = new AsyncSubject();
            this.contextPermissionsPending[contextKey] = pending;
            this.rpc.users.permissions({contextType: type, contextId: contextId}).then((result) => {
              this.errorCount = 0;
              this.contextPermissions[contextKey] = result;
              pending.next({permissions: result, error: null});
              pending.complete();
            }, (error) => {
              error.displayed = true;
              pending.next({permissions: [], error: error});
              pending.complete();
            });
          }
          pending.subscribe((next) => {
            if (next.error) {
              this.countError();
              reject(next.error);
            } else resolve(next.permissions);
          });
        }
      }
    });
  }

  checkService(check: ServiceRowExt_Service): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.checkServiceUnsafe(check).then(result => {
        resolve(result);
      }, (error) => {
        resolve(false);
      });
    });
  }

  getServices(): Promise<Array<ServiceRowExt_Service>> {
    return new Promise((resolve, reject) => {
      if (this.services) {
        resolve(this.services);
      } else {
        if (this.nextRetry > new Date().getDate()) {
          resolve([]);
        } else {
          if (!this.servicesPending) {
            this.servicesPending = new AsyncSubject();
            this.rpc.users.services().then((result) => {
              this.errorCount = 0;
              this.services = result;
              this.servicesPending.next({services: result, error: null});
              this.servicesPending.complete();
            }, (error) => {
              error.displayed = true;
              this.servicesPending.next({services: [], error: error});
              this.servicesPending.complete();
            });
          }
          this.servicesPending.subscribe((next) => {
            if (next.error) {
              this.countError();
              reject(next.error);
            } else resolve(next.services);
          });
        }
      }
    });
  }

  private checkServiceUnsafe(service: ServiceRowExt_Service): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getServices().then((services) => {
        resolve(services.includes(service));
      }, (error) => {
        reject(error);
      });
    });
  }

  private countError() {
    this.errorCount++;
    // this 100 errorCount is kind of high just because it needs to happen a lot before the permission guards redirect to maintenance page
    // otherwise, the nextRetry safeguard would lead to a login page redirect instead, which is less desirable
    if (this.errorCount >= 100) {
      this.nextRetry = new Date().getDate() + 100;
      this.errorCount = 0;
    }
  }
}

/*
 * Below is a BNF representing the grammar used by the scanner and parser for expressions.
 * --------------------------------------------------------------------------------------------
 * <local-boolean> ::= <factor> { <boolean-operator> <local-boolean> }
 * <factor> ::= { <prefix> } [ <permission> | ( <local-boolean> ) ]
 * <permission> ::= <permission-value> { <context-id> }
 */
export interface PermissionCheck {
  type: "permission" | "unary_operator" | "binary_operator";
  operator?: "|" | "&" | "!";
  operand1?: PermissionCheck;
  operand2?: PermissionCheck;
  permission?: Permission;
  contextType?: SecurityContextType;
  contextId?: number;
}

export function parsePermissions(permissions: string): PermissionCheck {
  return parseLocalBoolean(tokenize(permissions), null);
}

function tokenize(permissions: string): Array<string> {
  const tokens = [];
  let builder = '';
  [...permissions].forEach(c => {
    if (c === '(') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push('(');
      builder = '';
    } else if (c === ')') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push(')');
      builder = '';
    } else if (c === '!') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push('!');
      builder = '';
    } else if (c === '&') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push('&');
      builder = '';
    } else if (c === '|') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push('|');
      builder = '';
    } else if (c === ' ') {
      if (builder.length > 0) tokens.push(builder);
      builder = '';
    } else {
      builder = builder + c;
    }
  });
  if (builder.length > 0) tokens.push(builder);
  builder = '';
  return tokens;
}

// * <local boolean> ::= <factor> { <boolean operator> <local boolean> }
function parseLocalBoolean(tokens: Array<string>, previousBoolean: PermissionCheck) {
  // <local boolean> ::= <factor> { <boolean operator> <local boolean> }
  let comparison = parseFactor(tokens);
  if (previousBoolean != null) {
    previousBoolean.operand2 = comparison;
    if (tokens[0] === "&" || tokens[0] === "|") {
      const newComparison: PermissionCheck = {type: "binary_operator", operator: tokens[0] as any, operand1: previousBoolean};
      tokens.shift();
      comparison = parseLocalBoolean(tokens, newComparison);
    } else {
      comparison = previousBoolean;
    }
  } else if (tokens[0] === "&" || tokens[0] === "|") {
    const newComparison: PermissionCheck = {type: "binary_operator", operator: tokens[0] as any, operand1: comparison};
    tokens.shift();
    comparison = parseLocalBoolean(tokens, newComparison);
  }
  return comparison;
}

function parseFactor(tokens: Array<string>) {
  // <factor> ::= { <prefix> } [ <permission> | ( <local boolean> ) ]
  let factor: PermissionCheck = null;

  if (tokens[0] === '!') {
    factor = {type: "unary_operator", operator: "!"};
    tokens.shift();
  }

  if (tokens[0] === '(') {
    // Left parenthesis
    tokens.shift();
    const expression = parseLocalBoolean(tokens, null)
    if (factor == null) {
      factor = expression;
    } else {
      factor.operand1 = expression;
    }
    // Right parenthesis
    tokens.shift();
  } else {
    const permissionToken = tokens[0];
    const splitIdx = permissionToken.indexOf("$") + 1;
    const hasContext = splitIdx > 0 && splitIdx < permissionToken.length;
    const contextId: string = hasContext ? permissionToken.substring(splitIdx) : null;
    const permissionVal = hasContext ? permissionToken.substring(0, splitIdx) : permissionToken;
    const contextType: string = PermissionContexts[permissionVal];
    const expression: PermissionCheck = {type: "permission", permission: permissionVal as any, contextType: contextType as any, contextId: contextId ? Number(contextId) : null};
    tokens.shift();
    if (factor == null) {
      factor = expression;
    } else {
      factor.operand1 = expression;
    }
  }
  return factor;
}
