import {RPCService} from "./rpc.service";
import {Permission, UIUserInfo} from "./rpc/rpc-models";
import {PermissionService} from "./permission.service";
import {BehaviorSubject} from "rxjs";

export class SessionService {
  private initialized: boolean = false;
  private user: BehaviorSubject<UIUserInfo> = new BehaviorSubject<UIUserInfo>(null);

  constructor(
    private readonly permissions: PermissionService,
    private readonly rpc: RPCService
  ) {
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise(resolve => {
      this.hasPermission("authenticated").then((result) => {
        resolve(result);
      }, (error) => {
        resolve(false);
      });
    });
  }

  isAuthenticatedUnsafe(): Promise<boolean> {
    return this.hasPermissionUnsafe("authenticated");
  }

  userInfo(): Promise<UIUserInfo> {
    return new Promise(resolve => {
      if (this.user.getValue()) resolve(this.user.getValue());
      else {
        this.rpc.users.getSelfInfo().then((user) => {
          this.updateUser(user);
          resolve(user);
        }, (error) => {
          error.displayed = true;
          resolve(null);
        });
      }
    });
  }

  observeUserInfo(): BehaviorSubject<UIUserInfo> {
    this.initialize();
    return this.user;
  }

  hasPermission(permission: Permission): Promise<boolean> {
    return this.permissions.evalPermissions(permission);
  }

  hasPermissionUnsafe(permission: Permission): Promise<boolean> {
    return this.permissions.evalPermissionsUnsafe(permission);
  }

  refreshSession(reloadProfileImage: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this.permissions.resetPermissions().then(() => {
        this.rpc.users.getSelfInfo().then((user) => {
          this.rpc.ws.resetSocket();
          this.updateUser(user);
          resolve();
        }, (error) => {
          resolve();
        });
      });
    });
  }

  private initialize() {
    if (!this.initialized) {
      this.initialized = true;
      this.userInfo();
    }
  }

  private updateUser(user: UIUserInfo) {
    this.user.next(user);
  }
}
