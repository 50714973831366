<template>
  <Breadcrumb class="shrink-0" :home="breadHome" :model="breadItems">
    <template #item="{ label, item, props }">
      <router-link v-if="item.to" :to="item.to">
        <a v-bind="props.action">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
        </a>
      </router-link>
      <a v-else-if="item.url" :href="item.url" :target="item.target" v-bind="props.action">
        <span v-if="item.icon" v-bind="props.icon" />
        <span v-bind="props.label">{{ label }}</span>
      </a>
      <a v-else :target="item.target" v-bind="props.action" class="font-bold">
        <span v-if="item.icon" v-bind="props.icon" />
        <span v-bind="props.label">{{ label }}</span>
      </a>
    </template>
  </Breadcrumb>
</template>

<script setup lang="ts">
import {useDestroyable} from "~/core/util/composition-util";
import {useCore} from "../core/core.service";
import {ref} from "vue";

const { autoUnsubscribe } = useDestroyable();

const core = useCore();

const breadHome = ref();
const breadItems = ref();

autoUnsubscribe(core.crumbs.observeBreadHome().subscribe(home => {
  breadHome.value = home;
}));

autoUnsubscribe(core.crumbs.observeBreadItems().subscribe(items => {
  breadItems.value = [...items];
}));
</script>
