import {ToastServiceMethods} from "primevue/toastservice";

export class ToastService {
  toast: ToastServiceMethods;

  constructor() {
  }

  private calculateLife(closeAfterMs: number | undefined){
    if (closeAfterMs === undefined) return 5000
    if (closeAfterMs === 0) return undefined;
    return closeAfterMs;
  }

  toastError(param: { message: string, closeAfterMs?: number }) {
    if (this.toast) {
      console.log(`Error: ${param.message}`);
      this.toast.add({severity: 'error', summary: 'Error', detail: param.message, life: this.calculateLife(param.closeAfterMs)});
    }
  }

  toastSuccess(param: { message: string, closeAfterMs?: number, actions?: any }) {
    if (this.toast) {
      this.toast.add({severity: 'success', summary: 'Success', detail: {message: param.message, actions: param.actions}, life: this.calculateLife(param.closeAfterMs)});
    }
  }

  setToast(t: ToastServiceMethods) {
    if (t) this.toast = t;
  }
}
