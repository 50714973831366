import {ComponentInternalInstance, computed, onMounted, onUnmounted, ref} from "vue";
import {Subject, Subscription} from "rxjs";
import {RouteLocationNormalizedLoaded} from "vue-router";

export function useWindowSize() {
  // Reactive references for window dimensions
  const windowWidth = ref(window.innerWidth);
  const windowHeight = ref(window.innerHeight);

  // Update function
  const updateWindowSize = () => {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
  };

  // Lifecycle hooks to manage event listeners
  onMounted(() => {
    window.addEventListener('resize', updateWindowSize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateWindowSize);
  });

  // Computed helpers
  const orientation = computed(() => windowWidth.value > windowHeight.value ? "landscape" : "portrait" );
  const layout = computed(() => windowWidth.value <= 768 ? "mobile" : "desktop");

  // Return refs and computed values
  return {
    windowWidth,
    windowHeight,
    orientation,
    layout
  };
}

export function useDestroyable() {
  let _subscriptions_: Array<Subscription> = [];
  const $destroy = ref(new Subject<void>());
  const autoUnsubscribe = (subscription: Subscription) => {
    if (!subscription) return;

    // Wrap the subscription to see whether it was unsubscribed manually and remove from our _subscriptions list if so
    // Save the original unsubscribe method
    const originalUnsubscribe = subscription.unsubscribe.bind(subscription);

    // Override the unsubscribe method
    subscription.unsubscribe = () => {
      // Call the original unsubscribe
      originalUnsubscribe();

      // Remove the subscription from the list
      const index = _subscriptions_.indexOf(subscription);
      if (index !== -1) {
        _subscriptions_.splice(index, 1);
      }
    };

    if (subscription) _subscriptions_.push(subscription);
  }
  const unsubscribeAll = () => {
    const subs = _subscriptions_;
    _subscriptions_ = [];
    subs.forEach(s => {
      try {
        s.unsubscribe();
      } catch (error) {
        console.error(error);
      }
    });
  }

  onMounted(() => {
  });

  onUnmounted(() => {
    unsubscribeAll();
    $destroy.value.next();
  });

  return { $destroy, autoUnsubscribe, unsubscribeAll };
}

export function isLastMatchedRoute(route: RouteLocationNormalizedLoaded, currentInstance: ComponentInternalInstance) {
  const matchedComponents = route.matched;
  const lastMatched = matchedComponents[matchedComponents.length - 1];
  return currentInstance && lastMatched && lastMatched.components.default === currentInstance.type;
}
