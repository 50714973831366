<template>
  <slot v-if="hasPermission"></slot>
</template>

<script setup lang="ts">
import {PropType, Ref, ref, watch} from "vue";
import {useCore} from "~/core/core.service";

const core = useCore();

const props = defineProps({
  permissions: {
    type: String as PropType<string>,
    required: true,
    default: ''
  }
});

const hasPermission: Ref<boolean> = ref(false);

watch(() => props.permissions, (value, oldValue, onCleanup) => {
  core.permissions.evalPermissions(props.permissions).then((result) => {
    hasPermission.value = result;
  });
}, { deep: true, immediate: true });
//
// core.permissions.evalPermissions(props.permissions).then((result) => {
//   hasPermission.value = result;
// });

</script>
<style>
</style>
