import {HopdoxMenuItem, MenuGenerator, MenuProducer} from "~/core/menu.service";
import {RouteLocationNormalizedLoaded} from "vue-router";
import {RPCService} from "~/core/rpc.service";
import {BehaviorSubject, Observable} from "rxjs";

export const DefaultMenu: MenuGenerator = (route: RouteLocationNormalizedLoaded, rpc: RPCService): MenuProducer => {
  const menuItems: HopdoxMenuItem[] = [
    // CONTRACTOR APP
    {
      service: "CONTRACTOR",
      title: "Hub",
      icon: "pi-sitemap",
      link: "/contractor/construction-projects",
      permissions: "cps_all",
      button: true,
    },
    {
      service: "CONTRACTOR",
      title: "Reports",
      icon: "pi-chart-bar",
      link: "/contractor/reports",
      permissions: "org_view$",
      button: true,
    },
    {
      service: "CONTRACTOR",
      title: "Settings",
      icon: "pi-wrench",
      link: "/contractor/settings",
      permissions: "org_view$",
      button: true,
    },
    {
      service: "CONTRACTOR",
      title: "Help",
      icon: "pi-question-circle",
      link: "/contractor/help",
      permissions: "org_view$",
      button: true,
    },

    // ADMIN APP
    {
      service: "ADMIN",
      title: "Dashboard",
      icon: "pi-home",
      link: "/admin/dashboard",
      permissions: "users_global$ || orgs_global$",
      button: true,
    },
    {
      service: "ADMIN",
      title: "Users",
      icon: "pi-users",
      link: "/admin/users",
      permissions: "users_global$",
      button: true,
    },
    {
      service: "ADMIN",
      title: "Organizations",
      icon: "pi-building",
      link: "/admin/organizations",
      permissions: "orgs_global$",
      button: true,
    },
    {
      service: "ADMIN",
      title: "E-Record",
      icon: "pi-book",
      link: "/admin/e-record/packages",
      permissions: "dev_manage_master_data$ | dev_manage_recipient_config$",
      button: true,
    },
    {
      service: "ADMIN",
      title: "Support",
      icon: "ti ti-heart-handshake",
      // link: "/admin/e-record",
      permissions: "support_notifications$",
      children: [
        {
          service: "ADMIN",
          title: "News",
          icon: "mail",
          link: "/admin/support/news",
          permissions: "support_news$",
        },
        {
          service: "ADMIN",
          title: "Notifications",
          icon: "mail",
          link: "/admin/support/notifications",
          permissions: "support_notifications$",
        },
      ],
      button: true,
    },
    {
      service: "ADMIN",
      title: "Payments",
      icon: "pi-money-bill",
      permissions: "payments_view_scheduled$ | payments_process_ach$ | payments_reports$",
      children: [
        {
          service: "ADMIN",
          title: "Accounting",
          icon: "list",
          link: "/admin/accounting",
          permissions: "payments_reports$",
        },
        {
          service: "ADMIN",
          title: "ACH",
          icon: "list",
          link: "/admin/payments/ach",
          permissions: "payments_process_ach$",
        },
      ],
      button: true,
    },
    {
      service: "ADMIN",
      title: "Tasks",
      icon: "pi-stopwatch",
      // link: "/admin/e-record",
      permissions: "tasks_global$",
      children: [
        {
          service: "ADMIN",
          title: "Tasks",
          icon: "list",
          link: "/admin/tasks/running",
          permissions: "tasks_global$",
        },
        {
          service: "ADMIN",
          title: "Servers",
          icon: "server",
          link: "/admin/tasks/servers",
          permissions: "tasks_global$",
        },
        {
          service: "ADMIN",
          title: "Queues",
          icon: "folder",
          link: "/admin/tasks/queues",
          permissions: "tasks_global$",
        },
        {
          service: "ADMIN",
          title: "Cron",
          icon: "calendar",
          link: "/admin/tasks/cron",
          permissions: "tasks_global$",
        }
      ],
      button: true,
    },
    // {
    //   service: "ADMIN",
    //   title: "Doc Recognition",
    //   icon: "pi-eye",
    //   link: "/admin/doc-recognition",
    //   permissions: "cps_global$",
    //   button: true,
    // },
    {
      service: "ADMIN",
      title: "System",
      icon: "pi-cog",
      permissions: "dev_data_sync$",
      children: [
        {
          service: "ADMIN",
          title: "Data Sync",
          icon: "tool",
          link: "/admin/system/data-sync",
          permissions: "dev_data_sync$",
        },
        {
          service: "ADMIN",
          title: "Encryption",
          link: "/admin/system/encryption",
          permissions: "dev_encryption$",
        },
        {
          service: "ADMIN",
          title: "Script",
          link: "/admin/system/script",
          permissions: "dev_script_execution$",
        },
        {
          service: "ADMIN",
          title: "Toolbox",
          icon: "tool",
          link: "/admin/system/toolbox",
          permissions: "dev_tools$",
        },
        {
          service: "ADMIN",
          title: "Toggles",
          icon: "tool",
          link: "/admin/system/toggles",
          permissions: "dev_manage_toggles$",
        },
        {
          service: "ADMIN",
          title: "Global Search",
          icon: "tool",
          link: "/admin/system/global-search",
          permissions: "dev_data_sync$",
        },
        {
          service: "ADMIN",
          title: "Construction Projects",
          icon: "pi-megaphone",
          link: "/admin/construction-projects",
          permissions: "cps_global$",
          button: true,
        },
      ]
    },
    {
      service: "ADMIN",
      title: "Playground",
      icon: "ti ti-fidget-spinner",
      permissions: "dev_data_sync$",
      children: [
        {
          service: "ADMIN",
          title: "Imagen XML",
          icon: "ti ti-file-type-xml",
          link: "/admin/demo/imagen-xml",
          permissions: "dev_data_sync$",
        },
        {
          service: "ADMIN",
          title: "Upload Demo",
          icon: "ti ti-upload",
          link: "/admin/demo/upload",
          permissions: "dev_data_sync$",
        },
        {
          service: "ADMIN",
          title: "WASM Demo",
          icon: "pi-box",
          link: "/admin/demo/wasm",
          permissions: "dev_data_sync$",
        },
      ]
    },
    // {
    //   service: "ERECORD_SUBMITTER",
    //   title: "Packages",
    //   icon: "ti ti-stack-2",
    //   link: "/e-record/packages",
    //   permissions: "ep_create$",
    // },
    // {
    //   service: "ERECORD_SUBMITTER",
    //   title: "Playground",
    //   icon: "ti ti-fidget-spinner",
    //   permissions: "er_submitter_upload$",
    //   children: [
    //     {
    //       service: "ERECORD_SUBMITTER",
    //       title: "Uploads",
    //       icon: "ti ti-upload",
    //       link: "/e-record/helper/uploads",
    //       permissions: "er_submitter_upload$",
    //     },
    //   ]
    // },
    {
      title: "Resume",
      icon: "pi-arrow-circle-left",
      link: "/resume",
      permissions: "user_resume",
    },
  ];

  return { key: "default", observable: new BehaviorSubject(menuItems)};
}

export const AdminErecordMenu: MenuGenerator = (route: RouteLocationNormalizedLoaded, rpc: RPCService): MenuProducer => {
  const basePath = `/admin/e-record`;
  const menuItems: HopdoxMenuItem[] = [
    {
      service: "ADMIN",
      title: "E-recording",
      breadcrumbPrevious: true,
    },
    {
      service: "ADMIN",
      title: "Packages",
      icon: "",
      link: `${basePath}/packages`,
      permissions: "er_submitters_global$ & ep_view$",
    },
    {
      service: "ADMIN",
      title: "Recipients",
      icon: "",
      link: `${basePath}/recipients`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Land Record Vendors",
      icon: "",
      link: `${basePath}/land-record-vendors`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Integrations",
      icon: "",
      link: `${basePath}/integrations`,
      permissions: "er_submitters_global$ & ep_view$",
    },
    {
      service: "ADMIN",
      title: "Package Processing",
      icon: "",
      link: `${basePath}/package-processing`,
      permissions: "support_manage_package_processing$ & support_package_actions$",
    },
    {
      service: "ADMIN",
      title: "Master Data",
      // link: `${basePath}/master-data/`,
      icon: "",
      expanded: true,
      permissions: "dev_manage_master_data$",
      children: [
        {
          service: "ADMIN",
          title: "Labels",
          link: `${basePath}/master-data/labels`,
          icon: "",
          permissions: "dev_manage_master_data$",
        },
        {
          service: "ADMIN",
          title: "Document Types",
          link: `${basePath}/master-data/document-types`,
          icon: "",
          permissions: "dev_manage_master_data$",
        },
        {
          service: "ADMIN",
          title: "Sub Types",
          link: `${basePath}/master-data/sub-types`,
          icon: "",
          permissions: "dev_manage_master_data$",
        },
        {
          service: "ADMIN",
          title: "Indexing Fields",
          link: `${basePath}/master-data/indexing-fields`,
          icon: "",
          permissions: "dev_manage_master_data$",
        },
        {
          service: "ADMIN",
          title: "Holidays",
          link: `${basePath}/master-data/holidays`,
          icon: "",
          permissions: "dev_manage_master_data$",
        },
      ]
    },
    {
      service: "ADMIN",
      title: "Document Recognition Testing",
      icon: "",
      link: `/admin/doc-recognition`,
      permissions: "dev_manage_master_data$",
    },
    {
      service: "ADMIN",
      title: "Test Indexing Form",
      icon: "",
      link: `${basePath}/test-indexing`,
      permissions: "dev_manage_master_data$",
    },
  ];
  return { key: "adminErecord", observable: new BehaviorSubject(menuItems)};
}

export const PackageMenu: MenuGenerator = (route: RouteLocationNormalizedLoaded, rpc: RPCService): MenuProducer => {
  const packageKey = route.params['packageKey'] as string;
  const basePath = `/admin/e-record/packages/${packageKey}`;
  const menuItems: HopdoxMenuItem[] = [
    {
      service: "ADMIN",
      title: "Packages",
      breadcrumbPrevious: true,
    },
    {
      service: "ADMIN",
      title: "Info",
      icon: "",
      link: `${basePath}/info`,
      permissions: "er_submitters_global$ & ep_view$",
    },
    {
      service: "ADMIN",
      title: "Transmit",
      icon: "",
      link: `${basePath}/transmit`,
      permissions: "er_submitters_global$ & ep_view$",
    },
    {
      service: "ADMIN",
      title: "Tasks",
      icon: "",
      link: `${basePath}/tasks`,
      permissions: "er_submitters_global$ & ep_view$",
    },
    ];
  return { key: "package_" + packageKey, observable: new BehaviorSubject(menuItems)};
}

export const IntegrationMenu: MenuGenerator = (route: RouteLocationNormalizedLoaded, rpc: RPCService): MenuProducer => {
  const integrationKey = route.params['integrationKey'] as string;
  const basePath = `/admin/e-record/integrations/${integrationKey}`;
  return { key: "integration_" + integrationKey, observable: new Observable<HopdoxMenuItem[]>((observer) => {
      const subscription = rpc.admin_recipient_integrations.observe.loadRecipientIntegrationOperations(integrationKey).subscribe((ops) => {
        const menuItems: HopdoxMenuItem[] = [
          {
            service: "ADMIN",
            title: "Integrations",
            breadcrumbPrevious: true,
          },
          {
            service: "ADMIN",
            title: "Info",
            icon: "",
            link: `${basePath}/info`,
            permissions: "dev_manage_recipient_integrations$",
          }
        ];
        ops.forEach(op => {
          menuItems.push(
            {
              service: "ADMIN",
              title: op.subTitle,
              subTitle: op.operation,
              icon: "",
              permissions: "dev_manage_recipient_integrations$",
              link: `${basePath}/op-${op.operation}`
            },
          );
        });
        menuItems.push(
          {
            service: "ADMIN",
            button: true,
            title: "Add Operation",
            icon: "pi pi-plus",
            link: `${basePath}/add-operation`,
            permissions: "dev_manage_recipient_integrations$",
          }
        );
        menuItems.push(
          {
            service: "ADMIN",
            title: "Export",
            icon: "",
            link: `${basePath}/export`,
            permissions: "dev_manage_recipient_integrations$",
          }
        );
        observer.next(menuItems);
      });
      return () => {
        subscription.unsubscribe();
      };
    })
  };
}

export const RecipientMenu: MenuGenerator = (route: RouteLocationNormalizedLoaded, rpc: RPCService): MenuProducer => {
  const recipientKey = route.params['recipientKey'] as string;
  const basePath = `/admin/e-record/recipients/${recipientKey}`;
  const menuItems: HopdoxMenuItem[] = [
    {
      service: "ADMIN",
      title: "Recipients",
      breadcrumbPrevious: true,
    },
    {
      service: "ADMIN",
      title: "Info",
      icon: "",
      link: `${basePath}/info`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Indexing Fields",
      icon: "",
      link: `${basePath}/indexing-fields`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Recipient Doc Types",
      icon: "",
      link: `${basePath}/recipient-types`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Doc Type Mappings",
      icon: "",
      link: `${basePath}/doc-type-mappings`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Recipient Helper Types",
      icon: "",
      link: `${basePath}/recipient-helper-types`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Option Lists",
      icon: "",
      link: `${basePath}/option-lists`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Workflow",
      icon: "",
      link: `${basePath}/workflow`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Margins",
      icon: "",
      link: `${basePath}/margins`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Fees",
      icon: "",
      link: `${basePath}/fees`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Operating Hours",
      icon: "",
      link: `${basePath}/operating-hours`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Holidays",
      icon: "",
      link: `${basePath}/holidays`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Closures",
      icon: "",
      link: `${basePath}/closures`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Instructions",
      icon: "",
      link: `${basePath}/instructions`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Payment Settings",
      icon: "",
      link: `${basePath}/payment-settings`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Integration",
      icon: "",
      link: `${basePath}/integration`,
      permissions: "dev_manage_recipient_config$",
    },
    {
      service: "ADMIN",
      title: "Export",
      icon: "",
      link: `${basePath}/export`,
      permissions: "dev_manage_recipient_config$",
    },
  ];
  return { key: "recipient_" + recipientKey, observable: new BehaviorSubject(menuItems)};
}

