import {RPCRequest} from "./rpc-request";
import {RPCError, RPCResponse} from "./rpc-response";
import {Observable, Subject} from "rxjs";
import {ErrorCode} from "./rpc-models";
import {ToastService} from "~/core/toast.service";
import {APP_VERSION} from "~/app.version";
import {getRPCTargetHost} from "~/core/rpc/rpc-target";

/** Provide a way to see whether a rejected Promise's error has been handled, and if not, fallback to default behavior */
class ErrorPromise<T> extends Promise<T> {
    constructor(executor: (resolve: (value: (PromiseLike<T> | T)) => void, reject: (reason?: any) => void) => void, private errorHandler: (reason?: any) => void) {
        super(executor);
    }

    then<TResult1 = T, TResult2 = never>(
        onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null
    ): Promise<TResult1 | TResult2> {
        return super.then((value) => {
            if (onfulfilled) return onfulfilled(value);
            return value;
        }, (reason) => {
            // first call the actual onrejected, then our after-errorhandler
            if (onrejected) onrejected(reason);
            if (this.errorHandler) this.errorHandler(reason);
            return reason;
        });
    }
}

// TODO: abstract the HTTP stuff into a service
export class RPCConnector {
    private url: string;
    private host: string;

    private useVerboseRpcPaths: boolean = false;
    private nextAppVersion: Subject<string> = new Subject<string>();
    private forceUpdate: Subject<void> = new Subject<void>();
    private errors: Subject<RPCError> = new Subject<RPCError>();

    constructor(
        private readonly document: Document,
        private readonly toast: ToastService,
    ) {
        this.host = getRPCTargetHost(document);
        this.url = this.host + '/rpc';
    }

    setUseVerboseRpcPaths(value: boolean) {
        this.useVerboseRpcPaths = value;
    }

    observeNextAppVersion(): Observable<string> {
        return this.nextAppVersion;
    }

    observeForceUpdate(): Observable<void> {
        return this.forceUpdate;
    }

    observeErrors(): Observable<RPCError> {
        return this.errors;
    }

    rpcCall<I, O>(namespace: string, method: string, input: I): Promise<O> {
        const request: RPCRequest<I> = {
            namespace: namespace,
            method: method,
            data: input,
            trace: false
        };
        return this.invokeRpc(request);
    }

    getServerLink(path: string): string {
        let serverPath = this.host + path;
        serverPath += serverPath.indexOf("?") == -1 ? "?" : "&"
        serverPath += "subsessionid="
        if (window.sessionStorage.getItem('subsessionid')){
            serverPath += window.sessionStorage.getItem('subsessionid')
        }
        return serverPath
    }

    rpcFileSubmission<I, O>(path: string, input: I, file: File): Promise<O> {
        function numberBytes(num: number) {
            return ([num >> 24 & 255, num >> 16 & 255, num >> 8 & 255, num >> 0 & 255])
        }
        const jsonString = JSON.stringify(input);

        // Json Bytes
        const jsonBytes = new TextEncoder().encode(jsonString);
        const jsonLengthJavaUnsignedInteger = new Uint8Array(numberBytes(jsonBytes.length));
        const blobLengthJavaUnsignedInteger = new Uint8Array(numberBytes(file.size));

        const combinedBlob = new Blob([
            jsonLengthJavaUnsignedInteger,
            new TextEncoder().encode("\n"),
            jsonBytes,
            new TextEncoder().encode("\n"),
            blobLengthJavaUnsignedInteger,
            new TextEncoder().encode("\n"),
            file
        ]);

        const options = {
            method: 'post',
            headers: new Headers(this.addSubsessionHeaderIfNeeded({
                "Content-Type": "application/octet-stream",
            })),
            body: combinedBlob,
            credentials: 'include'
        } as const;
        let url = this.host + "/" + path;
        return this.buildErrorPromise(url, options);
    }

    private invokeRpc<I, O>(request: RPCRequest<I>): Promise<O> {
        const options = {
            method: 'post',
            headers: new Headers(this.addSubsessionHeaderIfNeeded({
                'Content-Type': 'application/json'
            })),
            body: JSON.stringify(request),
            credentials: 'include'
        } as const;
        let url = this.url;
        // flutter wrapper right now is only proxying the /rpc path itself, so don't augment it if running in a native wrapper
        if (this.useVerboseRpcPaths) url = url + "/" + request.namespace + "/" + request.method;
        return this.buildErrorPromise(url, options);
    }

    private addSubsessionHeaderIfNeeded(headers: Record<string, string>): Record<string, string>{
      if (window.sessionStorage.getItem('subsessionid')){
        headers['X-Subsession'] = window.sessionStorage.getItem('subsessionid')
      }
      return headers;
    }

    private buildErrorPromise<O>(url: string, options: RequestInit): ErrorPromise<O>{
        return new ErrorPromise((resolve, reject) => {
            fetch(url, options)
                .then((response) => response.json() as Promise<RPCResponse<O>>)
                .then((result) => {
                    let versionMismatch = false;
                    if (APP_VERSION && result && result.version && !(typeof result.version === 'undefined')) {
                        if (APP_VERSION !== result.version) {
                            // the backend has changed, so need to refresh
                            versionMismatch = true;
                            this.nextAppVersion.next(result.version);
                        }
                    }

                    if (result.success) {
                        return result.data;
                    } else {
                        if (result.error && result.error.code === ErrorCode.NoMethod && versionMismatch) {
                            // there's a clear API incompatibility, so trigger the process to force the update
                            this.forceUpdate.next();
                            result.error.displayed = true;
                        }
                        this.errors.next(result.error);
                        return Promise.reject(result.error);
                    }
                }, (error) => {
                  if ('message' in error && 'stack' in error && error.message === "Failed to fetch") {
                    return Promise.reject({
                      maintenance: true,
                      displayMessage: "There seems to be network connectivity trouble."
                    })
                  } else if ('status' in error && 'ok' in error && 'statusText' in error && error.status === 0 && !error.ok && error.statusText === "Unknown Error") {
                    return Promise.reject({
                      maintenance: true,
                      displayMessage: "There seems to be network connectivity trouble."
                    })
                  } else {
                    return Promise.reject(error);
                  }
                }).then((result) => resolve(result), (error) => reject(error));
        }, (error) => {
            // automatically display the error message if it hasn't been handled already
            if (error && !error.displayed && error.displayMessage) {
                this.toast.toastError({
                    message: error.displayMessage
                });
                // clear the displayMessage so that it doesn't get displayed again later...
                error.displayed = true;
            } else if (!error) {
                this.toast.toastError({
                  message: "An unknown error occurred."
                });
                // clear the displayMessage so that it doesn't get displayed again later...
                error.displayed = true;
            }
        });
    }
}
