<template>
  <HopToast />
  <ConfirmDialog></ConfirmDialog>
  <BlockUI :blocked="waiting">
    <ProgressSpinner v-if="waiting" style="position: fixed;top: 50%;left:50%;transform: translate(-50%, -50%);" />
    <div class="flex h-screen bg-gray-50">
      <Sidebar />

      <div class="flex-1 flex flex-col overflow-hidden">
        <Header />
        <Breadcrumbs class="rounded-none border-t border-b"/>
        <main :class="mainClass">
          <div v-if="contentClass" :class="contentClass">
            <slot />
          </div>
          <slot v-else></slot>
        </main>
      </div>
    </div>
  </BlockUI>
</template>

<script setup lang="ts">
import Sidebar from "./Sidebar.vue";
import Header from "./Header.vue";
import {useToast} from "primevue/usetoast";
import {useCore} from "../core/core.service";
import Breadcrumbs from "~/components/Breadcrumbs.vue";
import {useDestroyable} from "~/core/util/composition-util";
import {ref} from "vue";
import HopToast from "~/components/HopToast.vue";

const { autoUnsubscribe } = useDestroyable();

const toast = useToast();
const core = useCore();
core.toast.setToast(toast);

const mainClass = ref();
const contentClass = ref();
updateLayoutClasses(null);

function updateLayoutClasses(classes: { main: string, content: string }) {
  if (classes) {
    mainClass.value = classes.main;
    contentClass.value = classes.content;
  } else {
    mainClass.value = "flex-1 overflow-x-hidden overflow-y-auto";
    contentClass.value = "container mx-auto p-4 md:p-8";
  }
}

autoUnsubscribe(core.nav.observeActiveRoute().subscribe(route => {
  const layoutClasses = route && route.meta && route.meta.layoutClasses as { main: string, content: string };
  updateLayoutClasses(layoutClasses);
}));

const waiting = ref(false);
autoUnsubscribe(core.wait.observeWaiting().subscribe(currentWaiting => {
  waiting.value = currentWaiting;
}));

</script>
