<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="isOpen ? 'block' : 'hidden'"
      @click="isOpen = false"
      class="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
    ></div>
    <!-- End Backdrop -->

    <div
      :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-white lg:translate-x-0 lg:static lg:inset-0 border-r-1 border-gray-300 shadow-md"
    >
      <div class="flex items-center justify-center mt-8">
        <div class="flex items-center">
          <router-link :to="'/'">
            <img class="w-44" src="/assets/images/logo-black.svg" alt="Hopdox Logo">
          </router-link>
        </div>
      </div>

      <nav class="mt-10">
        <PanelMenu id="main-menu" v-model:expandedKeys="expandedKeys" :model="items" :pt="menuPassThrough">
          <template #item="{ item }">
            <router-link v-if="item.to" :to="item.to" class="p-panelmenu-header-action text-neutral-900 flex flex-row p-4">
              <div class="pr-2">
                <span v-if="item.icon" :class="'pi ' + item.icon" data-pc-section="headericon"></span>
              </div>
              <div>
                {{ item.label }}
                <div v-if="item.subTitle" class="block font-normal text-sm">{{ (item as any).subTitle }}</div>
              </div>
            </router-link>
            <div v-else class="p-panelmenu-header-action text-neutral-900 flex flex-row p-4">
              <div class="pr-2">
                <svg v-if="item.items" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline-block p-icon p-submenu-icon" aria-hidden="true" data-pc-section="submenuicon">
                  <path d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z" fill="currentColor"></path>
                </svg>
                <span v-if="item.icon" :class="'p-menuitem-icon pi ' + item.icon" data-pc-section="headericon"></span>
              </div>
              <div>
                {{ item.label }}
                <div v-if="item.subTitle" class="block font-normal text-sm">{{ (item as any).subTitle }}</div>
              </div>
            </div>
          </template>
        </PanelMenu>
        <div class="mt-8 mx-auto rounded-full bg-gray-200 w-fit py-2 p-4 text-sm">v. {{APP_VERSION}}</div>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Ref, ref} from "vue";
import {MenuItem} from "~/vendor/MenuItem";
import {useCore} from "~/core/core.service";
import {useDestroyable} from "~/core/util/composition-util";
import {useSidebar} from "~/hooks/useSidebar";
import {APP_VERSION} from "../app.version";

const { autoUnsubscribe } = useDestroyable();

const core = useCore();

const {isOpen} = useSidebar();

const items: Ref<MenuItem[]> = ref([]);
const expandedKeys: Ref<any> = ref({});

const menuPassThrough = {
  headerAction: {
    class: 'text-neutral-900'
  },
  headerContent: {
    class: 'bg-transparent border-t-0 border-l-0 border-r-0 border-b-1 rounded-none'
  },
  panel: {
    class: '!p-0'
  },
};

autoUnsubscribe(core.menu.observePrimeMenuOptions().subscribe(newItems => {
  items.value = newItems;
  expandedKeys.value = {};
  items.value.forEach((item: any) => {
    if (item.expanded) {
      expandedKeys.value[item.key] = true;
    }
  })
}));
</script>

<style>
#main-menu .router-link-active  {
  background-color: #09b880 !important;
  color: white !important;
}
#main-menu .p-panelmenu-panel  {
  margin-bottom: 0;
}

</style>
