import router from '~/router';
import {NavigationFailure, NavigationHookAfter, RouteLocationNormalized} from "vue-router";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {MenuItem} from "primevue/menuitem";
import {ParentLinkGenerator, TitleProducer} from "~/core/menu.service";
import {RPCService} from "~/core/rpc.service";

interface BreadcrumbItem {
  subscription: Subscription,
  menuItem: MenuItem
}

export class BreadcrumbService {
  private _breadHome: BehaviorSubject<MenuItem> = new BehaviorSubject<MenuItem>({
    icon: 'pi pi-home',
    url: '/'
  });
  private _breadItems: BehaviorSubject<Array<MenuItem>> = new BehaviorSubject<Array<MenuItem>>([]);
  private _activeBreadcrumbs: Map<string, BreadcrumbItem> = new Map();

  private _activeErMenuTitle: Subscription = null;
  private _erMenuTitle: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(
    private readonly rpc: RPCService,
  ) {
    router.afterEach(this.createBreadcrumbTracker());
    router.afterEach(this.createErMenuTitleTracker());
  }

  observeBreadHome(): Observable<MenuItem> { return this._breadHome.asObservable(); }
  observeBreadItems(): Observable<Array<MenuItem>> { return this._breadItems.asObservable(); }
  observeErMenuTitle(): Observable<string> { return this._erMenuTitle.asObservable(); }

  previousBreadcrumb(): MenuItem {
    if (this._breadItems.value && this._breadItems.value.length > 1) return this._breadItems.value[this._breadItems.value.length-2];
    return this._breadHome.value;
  }

  private updateBreadCrumbs(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const previousCrumbs: Map<string, BreadcrumbItem> = new Map<string, BreadcrumbItem>(this._activeBreadcrumbs);
    const breadcrumbItems: MenuItem[] = [
    ];

    let currentRoute = to;
    while (currentRoute) {
      const meta = currentRoute.meta;
      if (typeof meta.title === "function") {
        const titleProducer: TitleProducer = meta.title(to, this.rpc);
        if (titleProducer) {
          const toPath = breadcrumbItems.length ? currentRoute.path : null;
          const key = titleProducer.key + (toPath ? "_" + toPath : "");
          let breadcrumbItem: BreadcrumbItem = previousCrumbs.get(key);
          if (breadcrumbItem) {
            // keep the previous item
            previousCrumbs.delete(key);
            breadcrumbItems.push(breadcrumbItem.menuItem);
          } else {
            // create new breadcrumb
            const menuItem: MenuItem = {
              label: "",
              to: toPath
            };
            breadcrumbItem = {
              subscription: titleProducer.observable.subscribe((titleValue) => {
                menuItem.label = titleValue;
                // re-emit the items each time a title is updated
                this._breadItems.next(this._breadItems.value);
              }),
              menuItem: menuItem
            };
            breadcrumbItems.push(menuItem);
            this._activeBreadcrumbs.set(key, breadcrumbItem);
          }
        }
      }

      if (currentRoute.meta.parent && typeof currentRoute.meta.parent === 'function') {
        const parentPath = (currentRoute.meta.parent as ParentLinkGenerator)(currentRoute);
        currentRoute = router.resolve(parentPath);
      } else {
        currentRoute = null;
      }
    }

    // remove the previous leftover subscriptions
    previousCrumbs.forEach((value, key) => {
      this._activeBreadcrumbs.delete(key);
      value.subscription.unsubscribe();
    });

    breadcrumbItems.reverse();
    this._breadItems.next(breadcrumbItems);
  }

  private createBreadcrumbTracker(): NavigationHookAfter {
    return (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void) => {
      this.updateBreadCrumbs(to, from);
    };
  }

  private updateErMenuTitle(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    if (this._activeErMenuTitle) this._activeErMenuTitle.unsubscribe();
    for (let i = to.matched.length - 1; i >= 0; i--) {
      const matched = to.matched[i];
      if (matched.meta && typeof matched.meta.erMenuTitle === "function") {
        const titleProducer: TitleProducer = (matched.meta as any).erMenuTitle(to, this.rpc);
        if (titleProducer) {
          this._activeErMenuTitle = titleProducer.observable.subscribe((titleValue) => {
            // re-emit the items each time a title is updated
            this._erMenuTitle.next(titleValue);
          });
          break;
        }
      }
    }
  }

  private createErMenuTitleTracker(): NavigationHookAfter {
    return (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void) => {
      this.updateErMenuTitle(to, from);
    };
  }
}
