import * as _ from 'lodash';
import {Ref, toRaw} from "vue";

export function cloneDeep<T>(obj: T): T {
  return _.cloneDeep(obj);
}

export function merge<T>(target: T, toMerge: T): T {
  return _.merge(target, toMerge);
}

export function deepEquals(o1: any, o2: any): boolean {
  return _.isEqual(o1, o2);
}
function copyObjectValues<T extends object>(src: T|any, dest: T|any = null): T|any {
  if (!dest) dest = {};
  const allKeys = new Set([...Object.keys(src), ...Object.keys(dest)]);

  for (const key of allKeys) {
    if (src.hasOwnProperty(key)) {
      dest[key] = src[key];
    } else if (dest.hasOwnProperty(key)) {
      delete dest[key];
    }
  }

  return dest;
}

export function updateRows<T>(
  existingRows: T[],
  newRows: T[],
  idMatcher: (existingRow: T, newRow: T) => boolean,
  rowsRef: Ref = null,
  performReorder: boolean = false
): T[] {
  if (!existingRows) existingRows = [];
  let lastRow: T = null;
  let changed = false;
  if (newRows) {
    newRows.forEach((newRow, newIndex) => {
      const existingIndex = existingRows.findIndex(existingRow => idMatcher(existingRow, newRow));

      // if we're just updating an existing row or not appending to the list
      if (existingIndex !== -1 && (!performReorder || newIndex < existingRows.length)) {
        const existingRow = existingRows[existingIndex];

        const isDifferent = !deepEquals(existingRow, newRow);
        const needMove = performReorder && existingIndex !== newIndex;

        if (isDifferent) {
          // Row is different, replace it with the new data
          copyObjectValues(newRow, existingRows[existingIndex]);
          changed = true;
        } else {
          // no change
        }

        if (needMove) {
          const tmp = copyObjectValues(existingRows[existingIndex]);
          copyObjectValues(existingRows[newIndex], existingRows[existingIndex]);
          copyObjectValues(tmp, existingRows[newIndex]);
          changed = true;
        }
      } else {
        // Row doesn't exist, find the correct insertIndex based on the idMatcher
        const insertIndex = existingRows.findIndex(existingRow => idMatcher(existingRow, lastRow));

        if (insertIndex === -1) {
          // If no existing row matches, insert at the end
          existingRows.push({ ...newRow });
          changed = true;
        } else {
          // Insert the new row at the correct spot
          existingRows.splice(insertIndex + 1, 0, { ...newRow });
          changed = true;
        }
      }
      lastRow = newRow;
    });

    // Remove rows that are not present in the new data
    for (let i = existingRows.length - 1; i >= 0; i--) {
      if (!newRows.some(newRow => idMatcher(existingRows[i], newRow))) {
        // If the existing row doesn't match any new row, remove it.
        existingRows.splice(i, 1);
        changed = true;
      }
    };
  }

  if (changed && rowsRef) {
    console.log(existingRows);
    rowsRef.value = existingRows;
  }

  return existingRows;
}

// from https://bluedesk.blogspot.com/2023/11/settingresetting-vue-reactive-objects.html

/**
 * Recursively copies each field from src to dest, avoiding the loss of
 * reactivity. Used to copy values from an ordinary object to a reactive object.
 */
export function deepAssign<T extends object>(destObj: T, srcObj: T): void {
  const dest = destObj;
  const src = toRaw(srcObj);
  if (src instanceof Date) {
    throw new Error('[deepAssign] Dates must be copied manually.');
  } else if (Array.isArray(src)) {
    for (let i = 0; i < src.length; ++i) {
      if (src[i] === null) {
        (dest as any)[i] = null;
      } else if (src[i] instanceof Date) {
        (dest as any)[i] = new Date(src[i].getTime());
      } else if (Array.isArray(src[i]) || typeof src[i] === 'object') {
        if (typeof (dest as any)[i] === 'undefined' || !(dest as any)[i]) (dest as any)[i] = Array.isArray(src[i]) ? [] : {};
        deepAssign((dest as any)[i], src[i]);
      } else {
        (dest as any)[i] = toRaw(src[i]);
      }
    }
  } else if (typeof src === 'object') {
    for (const k in src) {
      if (src[k] === null) {
        (dest as any)[k] = null;
      } else if (src[k] instanceof Date) {
        (dest[k] as any) = new Date((src[k] as any).getTime());
      } else if (Array.isArray(src[k]) || typeof src[k] === 'object') {
        if (typeof dest[k] === 'undefined' || !dest[k]) (dest[k] as any) = Array.isArray(src[k]) ? [] : {};
        deepAssign(dest[k] as any, src[k] as any);
      } else {
        (dest[k] as any) = toRaw(src[k]);
      }
    }
  } else {
    throw new Error('[deepAssign] Unknown type: ' + (typeof src));
  }
}

/**
 * Deeply clones an object, eliminating common references. Used to create a
 * reactive object by copying from an ordinary object.
 */
export function deepClone<T>(origObj: T): T {
  const obj = toRaw(origObj);
  if (obj === undefined
    || obj === null
    || typeof obj === 'string'
    || typeof obj === 'number'
    || typeof obj === 'boolean') {
    return obj;
  } else if (Array.isArray(obj)) {
    return obj.reduce((acum, item) => [...acum, deepClone(item)], []);
  } else if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T;
  } else if (typeof obj === 'object') {
    return Object.entries(obj).reduce(
      (acum, [key, val]) => ({...acum, [key]: deepClone(val)}), {}) as T;
  } else {
    throw new Error('[deepClone] Tipo desconhecido: ' + (typeof obj));
  }
}
