import {first, Subject} from 'rxjs';
import {RPCService} from './rpc.service';
import {SessionService} from "~/core/session.service";
import {Toggles} from "~/core/rpc/rpc-models";

export class TogglesService {
  private toggles: Toggles = null;
  private loadingToggles: Subject<Toggles> = null;

  constructor(
    private readonly session: SessionService,
    private readonly rpc: RPCService
  ) {
    session.observeUserInfo().subscribe((user) => {
      if (this.toggles) {
        this.toggles = null;
      }
    });
  }

  getToggles(): Promise<Toggles> {
    if (this.toggles) return Promise.resolve(this.toggles);
    return new Promise((resolve, reject) => {
      // using the replay subject so that ultimately only one request will be made to retrieve the toggles from the server
      if (this.loadingToggles) {
        this.loadingToggles.pipe(first()).subscribe(toggles => {
          if (toggles) resolve(toggles);
          else resolve({} as any);
        });
      } else {
        this.loadingToggles = new Subject<Toggles>();
        this.session.isAuthenticated().then((result) => {
          if (result) {
            this.rpc.toggles.loadToggles().then((toggles) => {
              if (toggles) this.toggles = toggles;
              this.loadingToggles.next(toggles);
              this.loadingToggles = null;
              resolve(this.toggles);
            }, (error) => {
              this.loadingToggles.next(this.toggles);
              this.loadingToggles = null;
              resolve(this.toggles);
            });
          } else {
            this.loadingToggles.next(this.toggles);
            this.loadingToggles = null;
            resolve(this.toggles);
          }
        });
      }
    });
  }

  toggleEnabled(toggle: string): Promise<boolean> {
    const isEnabled = (toggle: any): boolean => {
      return (this.toggles as any)[toggle];
    };

    if (this.toggles) {
      return Promise.resolve(isEnabled(toggle));
    }
    else {
      return new Promise((resolve, reject) => {
        this.getToggles().then(()=> resolve(isEnabled(toggle)));
      });
    }
  }

  checkToggles(check: ToggleCheck): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getToggles().then((toggles) => {
        resolve(evaluateToggleCheck(check, toggles));
      }, (error) => {
        reject(error);
      });
    });
  }

  refreshToggles(): void {
    this.toggles = null;
  }
}

export type Toggle = keyof Toggles;

/**
 * I ripped this off of permission.service.ts, but refactored towards toggles.
 * It may or may not warrant refactoring into a generic binary expresssion checker.
 */

/*
 * Below is a BNF representing the grammar used by the scanner and parser for expressions.
 * --------------------------------------------------------------------------------------------
 * <local boolean> ::= <factor> { <boolean operator> <local boolean> }
 * <factor> ::= { <prefix> } [ <permission> | ( <local boolean> ) ]
 */
export interface ToggleCheck {
  type: "toggle" | "unary_operator" | "binary_operator";
  operator?: "|" | "&" | "!";
  operand1?: ToggleCheck;
  operand2?: ToggleCheck;
  toggle?: Toggle;
}

export function evaluateToggleCheck(check: ToggleCheck, toggles: Toggles): boolean {
  if (check.type === "toggle") {
    return !!toggles[check.toggle];
  } else if (check.type === "unary_operator") {
    const v1 = evaluateToggleCheck(check.operand1, toggles);
    if (check.operator === "!") return !v1;
  } else if (check.type === "binary_operator") {
    const v1 = evaluateToggleCheck(check.operand1, toggles);
    if (check.operator) {
      const v2 = evaluateToggleCheck(check.operand2, toggles);
      if (check.operator === "&") return v1 && v2;
      else if (check.operator === "|") return v1 || v2;
    }
  }
  return false;
}

export function parseToggles(toggles: string): ToggleCheck {
  return parseLocalBoolean(tokenize(toggles), null);
}

function tokenize(toggles: string): Array<string> {
  const tokens = [];
  let builder = '';
  [...toggles].forEach(c => {
    if (c === '(') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push('(');
      builder = '';
    } else if (c === ')') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push(')');
      builder = '';
    } else if (c === '!') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push('!');
      builder = '';
    } else if (c === '&') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push('&');
      builder = '';
    } else if (c === '|') {
      if (builder.length > 0) tokens.push(builder);
      tokens.push('|');
      builder = '';
    } else if (c === ' ') {
      if (builder.length > 0) tokens.push(builder);
      builder = '';
    } else {
      builder = builder + c;
    }
  });
  if (builder.length > 0) tokens.push(builder);
  builder = '';
  return tokens;
}

// * <local boolean> ::= <factor> { <boolean operator> <local boolean> }
function parseLocalBoolean(tokens: Array<string>, previousBoolean: ToggleCheck) {
  // <local boolean> ::= <factor> { <boolean operator> <local boolean> }
  let comparison = parseFactor(tokens);
  if (previousBoolean != null) {
    previousBoolean.operand2 = comparison;
    if (tokens[0] === "&" || tokens[0] === "|") {
      const newComparison: ToggleCheck = {type: "binary_operator", operator: tokens[0] as any, operand1: previousBoolean};
      tokens.shift();
      comparison = parseLocalBoolean(tokens, newComparison);
    } else {
      comparison = previousBoolean;
    }
  } else if (tokens[0] === "&" || tokens[0] === "|") {
    const newComparison: ToggleCheck = {type: "binary_operator", operator: tokens[0] as any, operand1: comparison};
    tokens.shift();
    comparison = parseLocalBoolean(tokens, newComparison);
  }
  return comparison;
}

function parseFactor(tokens: Array<string>) {
  // <factor> ::= { <prefix> } [ <permission> | ( <local boolean> ) ]
  let factor: ToggleCheck = null;

  if (tokens[0] === '!') {
    factor = {type: "unary_operator", operator: "!"};
    tokens.shift();
  }

  if (tokens[0] === '(') {
    // Left parenthesis
    tokens.shift();
    const expression = parseLocalBoolean(tokens, null)
    if (factor == null) {
      factor = expression;
    } else {
      factor.operand1 = expression;
    }
    // Right parenthesis
    tokens.shift();
  } else {
    const expression: ToggleCheck = {type: "toggle", toggle: tokens[0] as any};
    tokens.shift();
    if (factor == null) {
      factor = expression;
    } else {
      factor.operand1 = expression;
    }
  }
  return factor;
}
