import {TitleGenerator, TitleProducer} from "~/core/menu.service";
import {RouteLocationNormalized} from "vue-router";
import {RPCService} from "~/core/rpc.service";
import {Observable} from "rxjs";

export const RecipientTitle: TitleGenerator = (route: RouteLocationNormalized, rpc: RPCService): TitleProducer => {
  const recipientKey = route.params['recipientKey'] as string;
  return { key: "recipient_" + recipientKey, observable: new Observable<string>((observer) => {
      const subscription = rpc.admin_recipients.observe.loadRecipient(recipientKey).subscribe((info) => {
        observer.next(info.name);
      });
      return () => {
        subscription.unsubscribe();
      };
    })
  };
}

export const IntegrationTitle: TitleGenerator = (route: RouteLocationNormalized, rpc: RPCService): TitleProducer => {
  const integrationKey = route.params['integrationKey'] as string;
  return { key: "integration_" + integrationKey, observable: new Observable<string>((observer) => {
      const subscription = rpc.admin_recipient_integrations.observe.loadRecipientIntegration(integrationKey).subscribe((info) => {
        observer.next(info.info.name);
      });
      return () => {
        subscription.unsubscribe();
      };
    })
  };
}

export const PackageTitle: TitleGenerator = (route: RouteLocationNormalized, rpc: RPCService): TitleProducer => {
  const packageKey = route.params['packageKey'] as string;
  return { key: "package_" + packageKey, observable: new Observable<string>((observer) => {
      const subscription = rpc.admin_erecord_packages.observe.loadPackage({packageKey: packageKey}).subscribe((info) => {
        observer.next(info.name);
      });
      return () => {
        subscription.unsubscribe();
      };
    })
  };
}
