<template>
  <header
    class="flex items-center justify-between px-6 py-4 bg-white shadow-md"
  >
    <div class="flex items-center">
      <button
        @click="isOpen = true"
        class="text-gray-500 focus:outline-none lg:hidden"
      >
        <i class="pi pi-align-justify" />
      </button>
    </div>

    <div class="flex items-center">
      <Button icon="ti ti-search" text rounded severity="secondary" aria-label="Search" @click="searchVisible = true" />
      <div class="pr-4">
        <Button icon="ti ti-bell" text rounded severity="secondary" aria-label="Search" />
      </div>
      <div class="relative">
        <button @click="dropdownOpen = !dropdownOpen" class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-brand-600 hover:bg-brand-500 rounded-full dark:bg-gray-600 border">
          <span class="font-medium text-white dark:text-gray-300">{{initials}}</span>
        </button>
<!--        <button-->
<!--          @click="dropdownOpen = !dropdownOpen"-->
<!--          class="relative z-10 block w-8 h-8 overflow-hidden rounded-full shadow focus:outline-none"-->
<!--        >-->
<!--          <img-->
<!--            class="object-cover w-full h-full"-->
<!--            src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=296&q=80"-->
<!--            alt="Your avatar"-->
<!--          />-->
<!--        </button>-->

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 z-10 w-full h-full"
        ></div>

        <transition
          enter-active-class="transition duration-150 ease-out transform"
          enter-from-class="scale-95 opacity-0"
          enter-to-class="scale-100 opacity-100"
          leave-active-class="transition duration-150 ease-in transform"
          leave-from-class="scale-100 opacity-100"
          leave-to-class="scale-95 opacity-0"
        >
          <div
            v-show="dropdownOpen"
            class="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl"
          >
            <router-link to="#" class="block p-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-white flex flex-row items-center">
              <i class="ti ti-settings mr-2" />Account Settings
            </router-link>
            <hop-if permissions="user_resume">
              <router-link to="/resume" class="block p-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-white flex flex-row items-center">
                <i class="ti ti-player-play mr-2" />Resume
              </router-link>
            </hop-if>
            <router-link to="/logout" class="block p-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-white flex flex-row items-center">
              <i class="ti ti-login mr-2" />Log out
            </router-link>
          </div>
        </transition>
      </div>
    </div>
    <Dialog v-model:visible="searchVisible" modal :style="{ width: '50rem' }" position="top" :dismissable-mask="true" :pt="searchDialogPT">
      <IconField iconPosition="left" class="w-full mt-2">
        <InputIcon class="pi pi-search"></InputIcon>
        <InputText id="global-search" v-model="searchQuery" placeholder="Search" size="large" :style="{ width: '100%' }" @keyup.enter="search()"/>
      </IconField>
      <div class="flex flex-col h-full justify-center align-middle text-center !h-32" v-if="!searchResults">
        <p class="text-xs text-gray-600">No Recent Searches</p>
      </div>
      <ul v-if="searchResults" class="p-4">
        <template v-for="(result, index) in searchResults">
          <template v-if="result.type ==='PACKAGE'">
            <PackageSearchResult :result="result" @click="closeAndClearSearch()"></PackageSearchResult>
          </template>
          <template v-if="result.type ==='DOCUMENT'">
            <DocumentSearchResult :result="result" @click="closeAndClearSearch()"></DocumentSearchResult>
          </template>
        </template>
      </ul>
      <template #footer>
        <div class="w-full flex flex-row items-center text-xs border-t pt-4">
          <div class="mr-2 bg-gray-100 rounded p-0.5"><i class="ti ti-arrows-sort"/></div> Navigate
          <div class="mr-2 bg-gray-100 rounded p-0.5 mx-2"><i class="ti ti-arrow-back"/></div> Open
          <div class="px-2"><span class="border rounded font-bold p-0.5 bg-gray-100">ESC</span> Close</div>
        </div>
      </template>
    </Dialog>
  </header>
</template>

<script setup lang="ts">
import {ref} from "vue";
import { useSidebar } from "../hooks/useSidebar";
import HopIf from "~/components/HopIf.vue";
import {useCore} from "~/core/core.service";
import {UISearchResult, UIUserInfo} from "~/core/rpc/rpc-models";
import hotkeys from 'hotkeys-js';
import PackageSearchResult from "~/components/search/PackageSearchResult.vue";
import DocumentSearchResult from "~/components/search/DocumentSearchResult.vue";

const core = useCore();

const dropdownOpen = ref(false);
const { isOpen } = useSidebar();

const initials = ref("");
const searchVisible = ref(false);
const searchQuery = ref("");
const searchInput = ref(null);
let searchResults = ref(null as Array<UISearchResult>);
let orgKey = ref(undefined as string);

core.rpc.er_submitter_uploads.getSubmitterOrgs().then((orgKeys) => {
  orgKey.value = orgKeys[0];
});

const searchDialogPT = {
  header: {
    class: 'p-4'
  },
  closeButton: {
    class: 'hidden'
  },
  content: {
    class: 'py-0'
  },
};

core.rpc.users.getSelfInfo().then((self: UIUserInfo) => {
  initials.value = self.firstName.charAt(0) + self.lastName.charAt(0);
});

hotkeys('ctrl+k, command+k', function(event, handler){
  searchVisible.value = !searchVisible.value;
  setTimeout(() => {
    console.log(searchInput.value)
    // searchInput.value.focus();
    document.getElementById('global-search').focus();
  })
});

function search() {
  core.rpc.global_search.search({orgKey: orgKey.value, query: searchQuery.value}).then((results) => {
    console.log('search result:', results);
    searchResults.value = results;
  })
}

function closeAndClearSearch() {
  searchVisible.value = false;
  searchResults.value = [];
}

</script>
