import {BehaviorSubject, Observable} from "rxjs";

export class WaitService {
  private waitCount: number = 0;
  private waitStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
  ) {
  }

  isWaiting(): boolean {
    return this.waitStatus.value;
  }

  observeWaiting(): Observable<boolean> {
    return this.waitStatus.asObservable();
  }

  startWait() {
    this.waitCount++;
    this.updateWaitStatus();
  }

  endWait() {
    this.waitCount--;
    if (this.waitCount < 0) {
      this.waitCount = 0;
      throw new Error("Logic error. Wait count below zero.");
    }
    this.updateWaitStatus();
  }

  private updateWaitStatus() {
    const waiting = this.waitCount > 0;
    if (waiting !== this.isWaiting()) this.waitStatus.next(waiting);
  }
}
