import {createRouter, createWebHistory, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw, RouterView} from "vue-router";
import EmptyLayout from "./components/EmptyLayout.vue";
import {SecurityContextType, ServiceRowExt_Service} from "~/core/rpc/rpc-models";
import {MenuGenerator, TitleGenerator} from "~/core/menu.service";
import {AdminErecordMenu, IntegrationMenu, PackageMenu, RecipientMenu} from "~/core/menus";
import {RPCService} from "~/core/rpc.service";
import {BehaviorSubject} from "rxjs";
import {IntegrationTitle, PackageTitle, RecipientTitle} from "~/core/titles";
import ERecordLayout from "~/views/submitter/ERecordLayout.vue";
import {ServiceRedirect} from "~/core/navigation-guards";
import ERecordLayoutNew from "~/views/submitter/ERecordLayoutNew.vue";
import SettingsLayout from "~/views/submitter/settings/SettingsLayout.vue";
import {defineComponent, h} from "vue";

const PassthroughComponent = defineComponent((props) => { return () => { return h(RouterView); }; } );

enum DefaultOptions { Layout, Guards, Component, Children, Menu, Parent, Customization, }
interface OverrideMeta<T> {
  type: "layout"|"layoutClasses"|"guard"|"children"|"menu"|"title"|"erMenuTitle"|"parent"|"alias"|"customization"|"serviceRedirect"|"subPage"|"redirect";
  value: T;
}

const LayoutClasses = {
  Default: {
    main: "flex-1 overflow-x-hidden overflow-y-auto",
    content: "container mx-auto p-4 md:p-8"
  },
  FullArea: {
    main: "flex-1 flex flex-col overflow-hidden",
    content: null as string
  },
  FullAreaFixed: {
    main: "flex-1 flex flex-col overflow-hidden",
    content: "flex flex-col overflow-hidden",
  },
  FullAreaFullHeight: {
    main: "flex-1 flex flex-col overflow-hidden h-full",
    content: null as string
  },
};

// const DefaultLayout: OverrideMeta<any> = { type: "layout", value: DefaultOptions.Layout };
// const NoGuards: OverrideMeta<Guards> = { type: "guards", value: DefaultOptions.Guards };
const NoComponent = DefaultOptions.Component;
// const NoChildren: OverrideMeta = { type: "children", value: DefaultOptions.Children };
// const NoCustomization: OverrideMeta = { type: "menu", value: DefaultOptions.Customization };
// const DefaultMenu: OverrideMeta = { type: "parent", value: DefaultOptions.Menu };
// const HomeParent: OverrideMeta = { type: "customization", value: DefaultOptions.Parent };

type Customizer = (route: RouteRecordRaw) => void;

interface Guard {
  authenticated?: boolean;
  unauthenticated?: boolean;
  permissions?: string;
  permissionContext?: SecurityContextType;
  service?: ServiceRowExt_Service;
}

const Authenticated: Guard = { authenticated: true };
const Unauthenticated: Guard = { unauthenticated: true };

const routes: RouteRecordRaw[] = [
  createRoute("/test-slot-export", "testing/TestLayout", "TestLayout", layout(EmptyLayout)),
  createRoute("/maintenance", "views/Maintenance", "Maintenance", layout(EmptyLayout)),
  createRoute("/update", "views/Update", "Update", layout(EmptyLayout)),
  createRoute("/login", "views/Login", "Login", layout(EmptyLayout), guard(Unauthenticated), alias("/")),
  createRoute("/logout", "views/Logout", "Logout", layout(EmptyLayout)),
  createRoute("/resume", "views/Resume", "Resume Session", layout(EmptyLayout)),
  createRoute("/masquerade/:user_id", "views/Masquerade", "Masquerade Session"),
  createRoute("/forgot-password", "views/ForgotPassword", "Forgot Password", layout(EmptyLayout), guard(Unauthenticated)),
  createRoute("/register", "views/Register", "Register", layout(EmptyLayout), guard(Unauthenticated)),
  createRoute("/register/verify", "views/RegisterVerify", "Verify", layout(EmptyLayout)),
  createRoute("/register/accept", "views/AcceptInvite", "Verify", layout(EmptyLayout)),
  createRoute("/profile", "views/Profile", "Profile", guard(Authenticated)),
  createRoute("/dashboard", "views/Dashboard", "Dashboard", guard(Authenticated)),
  createRoute("/blank", "views/Blank", "Blank"),
  createRoute("/forms", "views/FormExamples", "Form Examples"),
  createRoute("/noc/:section/:key?", "views/NOC", "Notice of Commencement", layout(EmptyLayout)),
  createRoute("/admin", NoComponent, "Admin", guard({ authenticated: true, permissionContext: "GLOBAL_ADMIN" }),
    children([
      createRoute("dashboard", "views/admin/AdminDashboard", "Dashboard"),
      createRoute("users", "views/admin/AdminUsers", "Users"),
      createRoute("organizations", "views/admin/AdminOrganizations", "Organizations"),
      createRoute("construction-projects", "views/admin/AdminConstructionProjects", "Construction Projects"),
      createRoute("e-record/menu", "views/admin/AdminERecord", "E-Record", menu(AdminErecordMenu), parent('/admin')),
      // e-record package management
      createRoute("e-record/masquerade/:user_id/package/:package_id", "views/admin/packages/MasqueradeIntoPackage", "Load Submitter Package", layout(EmptyLayout)),
      createRoute("e-record/packages", "views/admin/AdminErecordPackages", "Packages", parent('/admin'), alias("/admin/e-record"), menu(AdminErecordMenu)),
      createRedirectRoute("e-record/packages/:recipientKey", "/admin/e-record/packages/:packageKey/info"),
      createRoute("e-record/packages/:packageKey/:page", "views/admin/packages/AdminPackageView", "Package", title(PackageTitle), layoutClasses(LayoutClasses.FullArea), menu(PackageMenu), parent('/admin/e-record/packages')),
      // e-record master data management
      createRedirectRoute("e-record/master-data", "/admin/e-record/master-data/labels"),
      createRoute("e-record/master-data/:tab", "views/admin/masterdata/AdminMasterData", "Master Data", layoutClasses(LayoutClasses.FullArea), parent('/admin'), menu(AdminErecordMenu)),
      // integrations management
      createRoute("e-record/integrations", "views/admin/recipients/AdminRecipientIntegrations", "Integrations", parent('/admin'), menu(AdminErecordMenu)),
      createRedirectRoute("e-record/integrations/:integrationKey", "/admin/e-record/integrations/:integrationKey/info"),
      createRoute("e-record/integrations/:integrationKey/:page", "views/admin/recipients/integrations/AdminRecipientIntegrationView", "Integration", title(IntegrationTitle), layoutClasses(LayoutClasses.FullArea), menu(IntegrationMenu), parent('/admin/e-record/integrations')),
      // package processing management
      createRoute("e-record/package-processing", "views/admin/packages/AdminPackageProcessing", "Package Processing", layoutClasses(LayoutClasses.FullArea), parent('/admin'), menu(AdminErecordMenu)),
      // recipient management
      createRoute("e-record/land-record-vendors", "views/admin/recipients/AdminLandRecordVendors", "Land Record Vendors", parent('/admin'), menu(AdminErecordMenu)),
      createRoute("e-record/recipients", "views/admin/recipients/AdminRecipients", "Recipients", parent('/admin'), menu(AdminErecordMenu)),
      createRedirectRoute("e-record/recipients/:recipientKey", "/admin/e-record/recipients/:recipientKey/info"),
      createRoute("e-record/recipients/:recipientKey/:page", "views/admin/recipients/AdminRecipientEditor", "Recipient", title(RecipientTitle), layoutClasses(LayoutClasses.FullArea), menu(RecipientMenu), parent('/admin/e-record/recipients')),
      createRoute("e-record/recipients/:recipientKey/:page/:tab", "views/admin/recipients/AdminRecipientEditor", "Document Types", layoutClasses(LayoutClasses.FullArea), menu(RecipientMenu), parent('/admin/e-record/recipients/:recipientKey/:page')),
      createRoute("e-record/test-indexing", "views/admin/AdminTestIndexingFields", "Test Indexing", parent('/admin'), menu(AdminErecordMenu)),
      createRoute("e-record/submitter/:submitterKey", "views/admin/submitters/AdminSubmitterInfo", "Submitter Info"),
      createRoute("e-record/submitter/:submitterKey/edit", "views/admin/submitters/AdminSubmitterEdit", "Submitter Edit"),
      createRoute("support", NoComponent, "Support", parent("/admin/dashboard"), children([
        createRoute("news", "views/admin/support/AdminNews", "News"),
        createRoute("notifications", "views/admin/support/AdminNotifications", "Notifications"),
      ])),
      createRoute("tasks", NoComponent, "Tasks", parent("/admin/dashboard"), children([
        createRoute("running", "views/admin/tasks/AdminTasks", "Tasks"),
        createRoute("servers", "views/admin/tasks/AdminTaskServers", "Servers", parent("/admin/tasks/running")),
        createRoute("ready/:ready_id", "views/admin/tasks/AdminTaskReady", "Details", parent("/admin/tasks/running")),
        createRoute("cron", "views/admin/tasks/AdminTaskCrons", "Crons", parent("/admin/tasks/running")),
        createRoute("queues", "views/admin/tasks/AdminTaskQueues", "Queues", parent("/admin/tasks/running")),
        createRoute("queues/:queue_id", "views/admin/tasks/AdminTaskQueue", "Queue Details", parent("/admin/tasks/queues")),
      ])),
      createRoute("doc-recognition", "views/admin/AdminDocRecognition", "Document Recognition", parent('/admin'), menu(AdminErecordMenu)),
      createRoute("accounting", "views/admin/payments/AdminAccountingReports", "Accounting Reports", layoutClasses(LayoutClasses.FullArea)),
      createRoute("payments/ach", "views/admin/payments/AdminAchPayments", "ACH Payments"),
      createRoute("payments/ach/:date", "views/admin/payments/AdminAchPaymentsDate", "ACH Breakdown", parent('/admin/payments/ach')),
      createRoute("system/encryption", "views/admin/AdminEncryption", "Encryption"),
      createRoute("system/script", "views/admin/AdminScript", "Script"),
      createRoute("system/data-sync", "views/admin/AdminDataSync", "Data Sync", layoutClasses(LayoutClasses.FullArea)),
      createRoute("system/data-sync/:tab", "views/admin/AdminDataSync", "Data Sync", layoutClasses(LayoutClasses.FullArea)),
      createRoute("system/toolbox", "views/admin/AdminToolbox", "Toolbox"),
      createRoute("system/quickbooks-auth", "views/admin/AdminQuickBooksAuth", "QuickBooks Auth"),
      createRoute("system/toggles", "views/admin/AdminToggles", "Toggles"),
      createRoute("system/global-search", "views/admin/AdminGlobalSearch", "Global Search"),
      createRoute("demo/wasm", "demo/WasmDemo", "Wasm Demo"),
      createRoute("demo/imagen-xml", "demo/ImagenXmlDemo", "Imagen XML"),
      createRoute("demo/upload", "demo/UploadDemo", "Upload Demo"),
    ])
  ),
  createRoute("/contractor", NoComponent, "Contractor", guard({ authenticated: true, permissionContext: "CONSTRUCTION_PROJECT" }),
    children([
      createRoute("construction-projects", "views/contractor/ConstructionProjects", "Construction Projects"),
    ])
  ),
  createRoute("/e-record", "views/submitter/ERecordLayoutNew", "Submitter", layout(EmptyLayout), guard({authenticated: true, service: "ERECORD_SUBMITTER" }),
      children([
        createRoute("reports/", "views/submitter/reports/ReportPage", "Reports", erMenuTitle("Reports"),
          children([
            createRoute("packages/:packageKey", "views/submitter/PackageWrapper", "Package", subPage(true)),
          ])
        ),
        createRoute("inbox/:workspace", "views/submitter/inbox/InboxPage", "Inbox", erMenuTitle("Packages"),
          children([
            createRoute("packages/:packageKey", "views/submitter/PackageWrapper", "Package", subPage(true)),
          ])
        ),
        createRedirectRoute("inbox/", "/e-record/inbox/all"),
        createRoute("search/:query?", "views/submitter/search/SearchPage", "Search", erMenuTitle("Search"),
          children([
            createRoute("packages/:packageKey", "views/submitter/PackageWrapper", "Package", subPage(true)),
          ])
        ),
        // createRoute("dashboard", "views/submitter/Dashboard", "Dashboard"),
        createRoute("packages/:packageKey?/", "views/submitter/Packages", "Packages", erMenuTitle("Packages"), serviceRedirect({
          replacers: [[/^/, "/admin"],[/$/,"/info"]],
          service: "ADMIN"
        })),
        createRedirectRoute("packages/", "/e-record/inbox"),
        createRedirectRoute("packages/:packageKey", "/e-record/inbox/packages/:packageKey"),
        // createRedirectRoute("packages/:packageKey/document/:documentKey", "inbox/packages/:packageKey/document/:documentKey"),
        // createRoute("packages/:packageKey/document/:documentKey", "views/submitter/Packages", "Packages"),
        createRoute("packages/:packageKey/history", "views/submitter/PackageHistoryPage", "Package History", layout(ERecordLayout)),
        createRoute("settings", "views/submitter/settings/SettingsLayout", "Settings", erMenuTitle("Settings"), redirect('/e-record/settings/profile'), children([
          createRoute("profile", "views/submitter/settings/Profile", "Profile"),
          createRoute("organization", "views/submitter/settings/Organization", "Organization"),
          createRoute("users", "views/submitter/settings/Users", "Users"),
          createRoute("billing", "views/submitter/settings/Billing", "Billing"),
          createRoute("features", "views/submitter/settings/Features", "Features"),
          createRoute("notifications", "views/submitter/settings/NotificationSettings", "Notifications"),
        ])),
        createRoute("recipients", "views/recipients/RecipientsLayout", "Recipients", erMenuTitle("Recipients"), redirect('/e-record/recipients/info'), children([
          createRoute("info/:recipientId?", "views/recipients/Recipients", "Recipient Info"),
        ])),
        createRoute("news", "views/submitter/ERecordNews", "News", erMenuTitle("News")),

        createRoute("helper/uploads", "views/submitter/temp/Uploads", "Test Uploads"),
        createRoute("helper/upload/:uploadKey", "views/submitter/temp/UploadAnalysis", "Upload Analysis"),
        createRoute("test/docviewer", "views/submitter/components/docviewer-old/SampleDocViewer", "DocViewer Demo", layoutClasses(LayoutClasses.FullAreaFixed)),
        // createRoute("reports", "views/submitter/Reports", "Reports"),
        createRoute("support", "views/submitter/Support", "Support", erMenuTitle("Support"))
      ])
  ),
  createRoute("/:pathMatch(.*)*", "views/NotFound", "Not Found", layout(EmptyLayout), guard(Unauthenticated), guard(Authenticated)), // using conflicting guards to basically force a redirect to home
];
// console.log(routes);

function loadPageComponent(componentPath: DefaultOptions.Component|string): Promise<any> {
  // this should resolve the particular component path into a loadable file path whether running in debug or prod builds
  const comps = import.meta.glob("./**/*.vue");
  const match: () => Promise<any> = comps[`./${componentPath}.vue`];
  return new Promise(resolve => {
    match().then(result => {
      resolve(result.default);
    });
  });
}

function createRedirectRoute(
  routePath: string,
  redirectPath: string,
): RouteRecordRaw {
  return {
    path: routePath,
    redirect: to => {
      console.log("REDIRECT: " + routePath);
      console.log(to);
      const { hash, params, query } = to;
      return redirectPath.replace(/:[a-zA-Z]+/g, (match: string) => {
        const paramName = match.slice(1); // Remove the colon
        return String(params[paramName]) || match; // Use the param value or the original placeholder
      });
    },
  };
}

function createRoute(
  routePath: string,
  componentPath: DefaultOptions.Component|string,
  title: string,
  ...overrides: OverrideMeta<any>[]
): RouteRecordRaw {
  const meta: any = {};
  let guardUnsaved = false;
  let redirect: any = null;
  meta.title = (route: RouteLocationNormalizedLoaded, rpc: RPCService) => {
    return {
      key: routePath,
      observable: new BehaviorSubject(title)
    };
  };
  let children = null;
  if (overrides) {
    overrides.forEach(o => {
      switch (o.type) {
        case "children": children = o.value; break;
        case "layout": meta.layout = o.value; break;
        case "layoutClasses": meta.layoutClasses = o.value; break;
        case "guard": {
          const guard: Guard = o.value;
          if (guard.authenticated != null && typeof guard.authenticated !== 'undefined') meta.authenticated = guard.authenticated;
          if (guard.unauthenticated != null && typeof guard.unauthenticated !== 'undefined') meta.unauthenticated = guard.unauthenticated;
          if (guard.permissions != null && typeof guard.permissions !== 'undefined') meta.permissions = guard.permissions;
          if (guard.permissionContext != null && typeof guard.permissionContext !== 'undefined') meta.permissionContext = guard.permissionContext;
          if (guard.service != null && typeof guard.service !== 'undefined') meta.service = guard.service;
        } break;
        case "serviceRedirect": {
          meta.serviceRedirects = meta.serviceRedirects || []
          meta.serviceRedirects.push(o.value)
        }
        case "parent": meta.parent = o.value; break;
        case "menu": meta.menu = o.value; break;
        case "title": meta.title = o.value; break;
        case "erMenuTitle": meta.erMenuTitle = o.value; break;
        case "subPage": meta.subPage = o.value; break;

        // cases that must be handled after route is built
        case "alias":
        case "customization": break;
        case "redirect": redirect = o.value; break;
      }
    });
  }

  const route: RouteRecordRaw = {
    path: routePath,
    component: !componentPath || componentPath === NoComponent ? PassthroughComponent : () => loadPageComponent(componentPath),
    meta: meta,
    children: children
  };

  if (redirect) route.redirect = redirect;

  if (overrides) {
    // only run customizers after the route is constructed
    overrides.forEach(o => {
      switch (o.type) {
        case "alias": route.alias = o.value; break;
        case "customization": {
          const customizer: Customizer = o.value;
          customizer(route);
        } break;
      }
    });
  }
  return route;
}

function alias(...paths: string[]): OverrideMeta<string[]> {
  return {
    type: "alias",
    value: paths
  }
}

function erMenuTitle(title: TitleGenerator|string): OverrideMeta<TitleGenerator> {
  if (typeof title === 'string') {
    const titleLabel = title;
    title = (route: RouteLocationNormalized, rpc: RPCService) => {
      return {key: titleLabel.toLowerCase(), observable: new BehaviorSubject(titleLabel)};
    };
  }
  return {
    type: "erMenuTitle",
    value: title
  }
}

function parent(path: string): OverrideMeta<(route: RouteLocationNormalizedLoaded) => string> {
  return {
    type: "parent",
    value: (route: RouteLocationNormalizedLoaded): string => {
      return path;
    }
  }
}

function layout(layout: any): OverrideMeta<any> {
  return {
    type: "layout",
    value: layout
  }
}

function layoutClasses(classes: { main: string, content: string }): OverrideMeta<any> {
  return {
    type: "layoutClasses",
    value: classes
  }
}

function guard(guards: Guard): OverrideMeta<Guard> {
  return {
    type: "guard",
    value: guards
  }
}

function serviceRedirect(serviceRedirect: ServiceRedirect): OverrideMeta<ServiceRedirect> {
  return {
    type: "serviceRedirect",
    value: serviceRedirect,
  }
}

function title(title: TitleGenerator|string): OverrideMeta<TitleGenerator> {
  if (typeof title === 'string') {
    const titleLabel = title;
    title = (route: RouteLocationNormalized, rpc: RPCService) => {
      return {key: titleLabel.toLowerCase(), observable: new BehaviorSubject(titleLabel)};
    };
  }
  return {
    type: "title",
    value: title
  }
}

function menu(menu: MenuGenerator): OverrideMeta<MenuGenerator> {
  return {
    type: "menu",
    value: menu
  }
}

function subPage(isSubPage: boolean): OverrideMeta<boolean> {
  return {
    type: "subPage",
    value: isSubPage
  }
}

function redirect(redirect: any): OverrideMeta<boolean> {
  return {
    type: "redirect",
    value: redirect
  }
}

function customize(customizer: Customizer): OverrideMeta<Customizer> {
  return {
    type: "customization",
    value: customizer
  }
}

function children(children: RouteRecordRaw[]): OverrideMeta<RouteRecordRaw[]> {
  return {
    type: "children",
    value: children
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
