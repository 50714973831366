import {
  AdminErecordPackageHandler_UIDebugTransmitData,
  AdminImagenTestHandler_PackageSummaries,
  AdminRecipientHandler_Mode,
  AdminRecipientHandler_UIAdminCopyGenericCountyConfigModel,
  AdminRecipientHandler_UILandRecordVendor,
  AdminRecipientIntegrationHandler_MultiTypeResponse,
  AdminRecipientIntegrationHandler_RecipientIntegrationOperation,
  AdminRecipientIntegrationHandler_ResponseOperationResult,
  ERRecipientIndexingField,
  ERRecipientOptionList,
  ErDocDataExtractionHandler_StatusData,
  ErIndexingData,
  ErIndexingData_StatusChange,
  ErIndexingRoot,
  ErPackageListsHandler_DefaultWorkSpace,
  ErReportsHandler_DefaultWorkSpace,
  ErSubmitterDocviewerHandler_DocPages,
  ErSubmitterDocviewerHandler_DocTypeFlat,
  ErSubmitterDocviewerHandler_DocViewerSummary,
  ErSubmitterDocviewerHandler_RecipientOrgOption,
  ErSubmitterHandler_RecipientState,
  ExportDataSet,
  FinancialModels_PaymentDetailLevel,
  FnAchBankRow_BankIntegration,
  FnAchFileRow_FnAchFileId,
  FnChargeRow_FnChargeId,
  FnPaymentRow_FnPaymentId,
  ImagenModels_AlteredPage,
  ImagenModels_InterpolateXmlRequest,
  ImagenModels_PageAlteration,
  IntegrationOperationBusiness_RenderStage,
  MasterDocTypeRowExt_DocTypeKey,
  MasterIndexingFieldRow_StandardOptionList,
  MasterLabelRow_MasterLabelId,
  OCRBlock,
  Permission,
  RecipientDocTypeListResponse,
  RecipientDocTypeRow_RecipientDocTypeId,
  RelayHandler_RelayRequest,
  RelayHandler_RelayResponse,
  ServiceRowExt_Service,
  TaskReadyRow_TaskReadyId,
  TaskServerRow_TaskServerId,
  TaskTypeRow_TaskTypeId,
  Toggles,
  UIACHPaymentGroup,
  UIAdminCreateManualTransaction,
  UIAdminDataImport,
  UIAdminDocumentAction,
  UIAdminERPackageSupportNote,
  UIAdminErecordPackageList,
  UIAdminErecordPackageList_AdminSearch,
  UIAdminGenericQueryResult,
  UIAdminManualRecordPackage,
  UIAdminManualRejectPackage,
  UIAdminMasterDocType,
  UIAdminMasterHoliday,
  UIAdminMasterIndexingField,
  UIAdminMasterLabel,
  UIAdminMasterSubDocType,
  UIAdminNews,
  UIAdminNotification,
  UIAdminPackageAction,
  UIAdminPackageProcessingHistory,
  UIAdminPackageTask,
  UIAdminPackageTaskAction,
  UIAdminRecipientClosure,
  UIAdminRecipientConfigHistory,
  UIAdminRecipientDocType,
  UIAdminRecipientFeecalcCondition,
  UIAdminRecipientFeecalcDocTarget,
  UIAdminRecipientFeecalcFee,
  UIAdminRecipientFeecalcInput,
  UIAdminRecipientHelperDocType,
  UIAdminRecipientHoliday,
  UIAdminRecipientHours,
  UIAdminRecipientIndexingField,
  UIAdminRecipientInfo,
  UIAdminRecipientInstruction,
  UIAdminRecipientIntegration,
  UIAdminRecipientIntegrationConfig,
  UIAdminRecipientIntegrationContextData,
  UIAdminRecipientIntegrationContextLibraryData,
  UIAdminRecipientIntegrationList,
  UIAdminRecipientIntegrationList_Search,
  UIAdminRecipientIntegration_IntegrationOp,
  UIAdminRecipientList,
  UIAdminRecipientList_Search,
  UIAdminRecipientMarginSettings,
  UIAdminRecipientMasterDocType,
  UIAdminRecipientMasterTypesUpdate,
  UIAdminRecipientOptionList,
  UIAdminRecipientPaymentSettings,
  UIAdminRecordingJurisdictionInfo,
  UIAdminRecordingJurisdictionInfo_StateGrouping,
  UIAdminRegisterUser,
  UIAdminStandardOptionList,
  UIAdminUserInfo,
  UIAdminUserOrgNotificationSettings,
  UIAutoMappedRecipientType,
  UIBilling,
  UIConstructionProjectInfo,
  UIConstructionProjectList,
  UIConstructionProjectList_AdminSearch,
  UIConstructionProjectList_Search,
  UIERDocument,
  UIERDocumentAnalysis,
  UIERHistoryEvent,
  UIERPackage,
  UIERPackageList,
  UIERPackageView,
  UIERReport,
  UIERSubmitterRecipient,
  UIERWorkspace,
  UIEditDefaultPaymentAccount,
  UIEditableCPRequirements,
  UIEditableConstructionProject,
  UIEditableERPackage,
  UIEditableOrganization,
  UIEditablePaymentAccount,
  UIEditableProfile,
  UIFile,
  UIGlobalSearchQuery,
  UIInviteUser,
  UIOrgInfo,
  UIOrgList,
  UIOrgList_Search,
  UIPermissionContext,
  UIRegisterUser,
  UIRegisterUserWithProject,
  UISearchResult,
  UISettingsGroups,
  UISubmitterRecipientInfo,
  UISubmitterUpload,
  UITasks_CronRow,
  UITasks_QueueDetails,
  UITasks_QueueSummary,
  UITasks_ServerSummary,
  UITasks_TaskRow,
  UIThumbnailLink,
  UITogglesList,
  UIToolDescription,
  UIUSState,
  UIUserAcceptInvite,
  UIUserInfo,
  UIUserInvite,
  UIUserList,
  UIUserList_Search,
  UpdatesTracker
} from './rpc-models';
import {RPCConnector} from "./rpc-connector";
import {WebSocketConnector} from "../websocket/web-socket-connector";
import {Observable} from "rxjs";

export class RPCHandlers {
  constructor(readonly connector: RPCConnector, readonly ws: WebSocketConnector) {}

  // ---> Namespace admin_accounting | Class co.coolhc.hopdox.handler.AdminAccountingHandler
  admin_accounting = {
    loadPaymentData: (payload: {startDate:string,endDate:string}): Promise<UIAdminGenericQueryResult> => { return this.connector.rpcCall("admin_accounting", "loadPaymentData", payload); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_construction_projects | Class co.coolhc.hopdox.handler.AdminConstructionProjectHandler
  admin_construction_projects = {
    loadConstructionProject: (key: string): Promise<UIConstructionProjectInfo> => { return this.connector.rpcCall("admin_construction_projects", "loadConstructionProject", key); },
    loadConstructionProjectList: (search: UIConstructionProjectList_AdminSearch): Promise<UIConstructionProjectList> => { return this.connector.rpcCall("admin_construction_projects", "loadConstructionProjectList", search); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_data_sync | Class co.coolhc.hopdox.handler.AdminDataSyncHandler
  admin_data_sync = {
    exportDataSet: (payload: {dataSet:ExportDataSet}): Promise<string> => { return this.connector.rpcCall("admin_data_sync", "exportDataSet", payload); },
    importDataSet: (payload: {preparedImport:UIAdminDataImport,csv:string}): Promise<void> => { return this.connector.rpcCall("admin_data_sync", "importDataSet", payload); },
    prepareImportDataSet: (payload: {csv:string}): Promise<UIAdminDataImport> => { return this.connector.rpcCall("admin_data_sync", "prepareImportDataSet", payload); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_dev_tools | Class co.coolhc.hopdox.handler.AdminDevToolsHandler
  admin_dev_tools = {
    approveQuickBooksAuthorizationSettings: (payload: {authorizationState:string,authorizationCode:string,realmId:string}): Promise<void> => { return this.connector.rpcCall("admin_dev_tools", "approveQuickBooksAuthorizationSettings", payload); },
    executeScript: (payload: {code:string}): Promise<string> => { return this.connector.rpcCall("admin_dev_tools", "executeScript", payload); },
    getQuickBooksAuthorizationURL: (payload: {resultUrl:string}): Promise<string> => { return this.connector.rpcCall("admin_dev_tools", "getQuickBooksAuthorizationURL", payload); },
    invokeTool: (payload: {tool:string}): Promise<void> => { return this.connector.rpcCall("admin_dev_tools", "invokeTool", payload); },
    listTools: (): Promise<UIToolDescription> => { return this.connector.rpcCall("admin_dev_tools", "listTools", null); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_encryption | Class co.coolhc.hopdox.handler.AdminEncryptionHandler
  admin_encryption = {
    encryptValue: (payload: {value:string,encryptionId:string}): Promise<string> => { return this.connector.rpcCall("admin_encryption", "encryptValue", payload); },
    getEncryptionIds: (): Promise<Array<string>> => { return this.connector.rpcCall("admin_encryption", "getEncryptionIds", null); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_erecord_package_processing | Class co.coolhc.hopdox.handler.AdminErecordPackageProcessingHandler
  admin_erecord_package_processing = {
    addPackageProcessingNote: (payload: {processingId:number,note:string}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "addPackageProcessingNote", payload); },
    addPackageProcessingNoteBulk: (payload: {processingNoteIds:Array<number>,note:string}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "addPackageProcessingNoteBulk", payload); },
    loadAllIncompletePackageProcessingTasks: (): Promise<Array<UIAdminPackageTask>> => { return this.connector.rpcCall("admin_erecord_package_processing", "loadAllIncompletePackageProcessingTasks", null); },
    setPackageProcessingActive: (payload: {processingId:number}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "setPackageProcessingActive", payload); },
    setPackageProcessingActiveBulk: (payload: {processingIds:Array<number>}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "setPackageProcessingActiveBulk", payload); },
    setPackageProcessingComplete: (payload: {processingId:number}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "setPackageProcessingComplete", payload); },
    setPackageProcessingCompleteBulk: (payload: {processingIds:Array<number>}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "setPackageProcessingCompleteBulk", payload); },
    setPackageProcessingPaused: (payload: {processingId:number}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "setPackageProcessingPaused", payload); },
    setPackageProcessingPausedBulk: (payload: {processingIds:Array<number>}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "setPackageProcessingPausedBulk", payload); },
    updatePackageProcessingNote: (payload: {processingNoteId:number,note:string}): Promise<void> => { return this.connector.rpcCall("admin_erecord_package_processing", "updatePackageProcessingNote", payload); },
    observe: {
      loadAllIncompletePackageProcessingTasks: (): Observable<Array<UIAdminPackageTask>> => { return this.ws.rpcSubscribe("admin_erecord_package_processing", "loadAllIncompletePackageProcessingTasks", null); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_erecord_packages | Class co.coolhc.hopdox.handler.AdminErecordPackageHandler
  admin_erecord_packages = {
    addERPackageSupportNote: (payload: {supportNote:UIAdminERPackageSupportNote}): Promise<void> => { return this.connector.rpcCall("admin_erecord_packages", "addERPackageSupportNote", payload); },
    doDocumentAction: (adminDocumentAction: UIAdminDocumentAction): Promise<void> => { return this.connector.rpcCall("admin_erecord_packages", "doDocumentAction", adminDocumentAction); },
    doPackageAction: (adminPackageAction: UIAdminPackageAction): Promise<void> => { return this.connector.rpcCall("admin_erecord_packages", "doPackageAction", adminPackageAction); },
    doPackageTaskAction: (adminPackageTaskAction: UIAdminPackageTaskAction): Promise<any> => { return this.connector.rpcCall("admin_erecord_packages", "doPackageTaskAction", adminPackageTaskAction); },
    findPackagesByRemotePackageId: (recipientPackageId: string): Promise<Array<UIERPackage>> => { return this.connector.rpcCall("admin_erecord_packages", "findPackagesByRemotePackageId", recipientPackageId); },
    generatePackageTransmitContent: (payload: {packageKey:string,connectorClass:string,includeImageBytes:boolean}): Promise<AdminErecordPackageHandler_UIDebugTransmitData> => { return this.connector.rpcCall("admin_erecord_packages", "generatePackageTransmitContent", payload); },
    getBasicDocPages: (payload: {documentKey:string}): Promise<Array<ImagenModels_AlteredPage>> => { return this.connector.rpcCall("admin_erecord_packages", "getBasicDocPages", payload); },
    getBasicDocPagesByDocCompositeId: (payload: {docCompositeId:number}): Promise<Array<ImagenModels_AlteredPage>> => { return this.connector.rpcCall("admin_erecord_packages", "getBasicDocPagesByDocCompositeId", payload); },
    getPackageCreator: (packageKey: string): Promise<UIAdminUserInfo> => { return this.connector.rpcCall("admin_erecord_packages", "getPackageCreator", packageKey); },
    loadPackage: (payload: {packageKey:string}): Promise<UIERPackage> => { return this.connector.rpcCall("admin_erecord_packages", "loadPackage", payload); },
    loadPackageList: (search: UIAdminErecordPackageList_AdminSearch): Promise<UIAdminErecordPackageList> => { return this.connector.rpcCall("admin_erecord_packages", "loadPackageList", search); },
    loadPackageProcessingTaskHistory: (payload: {packageKey:string}): Promise<Array<UIAdminPackageProcessingHistory>> => { return this.connector.rpcCall("admin_erecord_packages", "loadPackageProcessingTaskHistory", payload); },
    loadPackageProcessingTaskHistoryBlob: (payload: {blobId:number}): Promise<string> => { return this.connector.rpcCall("admin_erecord_packages", "loadPackageProcessingTaskHistoryBlob", payload); },
    loadPackageProcessingTasks: (payload: {packageKey:string}): Promise<Array<UIAdminPackageTask>> => { return this.connector.rpcCall("admin_erecord_packages", "loadPackageProcessingTasks", payload); },
    recordPackageManually: (manualRecordPackage: UIAdminManualRecordPackage): Promise<void> => { return this.connector.rpcCall("admin_erecord_packages", "recordPackageManually", manualRecordPackage); },
    rejectPackageManually: (manualRejectPackage: UIAdminManualRejectPackage): Promise<void> => { return this.connector.rpcCall("admin_erecord_packages", "rejectPackageManually", manualRejectPackage); },
    removeDocComposite: (payload: {docCompositeId:number}): Promise<void> => { return this.connector.rpcCall("admin_erecord_packages", "removeDocComposite", payload); },
    reretrieveProcessingStep: (processingId: number): Promise<void> => { return this.connector.rpcCall("admin_erecord_packages", "reretrieveProcessingStep", processingId); },
    viewRetrieveRecordResponse: (processingId: number): Promise<any> => { return this.connector.rpcCall("admin_erecord_packages", "viewRetrieveRecordResponse", processingId); },
    viewRetrieveRejectResponse: (processingId: number): Promise<any> => { return this.connector.rpcCall("admin_erecord_packages", "viewRetrieveRejectResponse", processingId); },
    observe: {
      getBasicDocPages: (payload: {documentKey:string}): Observable<Array<ImagenModels_AlteredPage>> => { return this.ws.rpcSubscribe("admin_erecord_packages", "getBasicDocPages", payload); },
      getBasicDocPagesByDocCompositeId: (payload: {docCompositeId:number}): Observable<Array<ImagenModels_AlteredPage>> => { return this.ws.rpcSubscribe("admin_erecord_packages", "getBasicDocPagesByDocCompositeId", payload); },
      loadPackage: (payload: {packageKey:string}): Observable<UIERPackage> => { return this.ws.rpcSubscribe("admin_erecord_packages", "loadPackage", payload); },
      loadPackageProcessingTaskHistory: (payload: {packageKey:string}): Observable<Array<UIAdminPackageProcessingHistory>> => { return this.ws.rpcSubscribe("admin_erecord_packages", "loadPackageProcessingTaskHistory", payload); },
      loadPackageProcessingTasks: (payload: {packageKey:string}): Observable<Array<UIAdminPackageTask>> => { return this.ws.rpcSubscribe("admin_erecord_packages", "loadPackageProcessingTasks", payload); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_master_data | Class co.coolhc.hopdox.handler.AdminMasterDataHandler
  admin_master_data = {
    addMasterHoliday: (holiday: UIAdminMasterHoliday): Promise<void> => { return this.connector.rpcCall("admin_master_data", "addMasterHoliday", holiday); },
    addMasterSubDocType: (subDocType: UIAdminMasterSubDocType): Promise<void> => { return this.connector.rpcCall("admin_master_data", "addMasterSubDocType", subDocType); },
    deleteMasterDocType: (masterDocKey: string): Promise<void> => { return this.connector.rpcCall("admin_master_data", "deleteMasterDocType", masterDocKey); },
    deleteMasterHoliday: (masterHolidayKey: string): Promise<void> => { return this.connector.rpcCall("admin_master_data", "deleteMasterHoliday", masterHolidayKey); },
    deleteMasterIndexingField: (path: string): Promise<void> => { return this.connector.rpcCall("admin_master_data", "deleteMasterIndexingField", path); },
    deleteMasterLabel: (masterLabelId: MasterLabelRow_MasterLabelId): Promise<void> => { return this.connector.rpcCall("admin_master_data", "deleteMasterLabel", masterLabelId); },
    deleteMasterSubDocType: (masterSubDocKey: string): Promise<void> => { return this.connector.rpcCall("admin_master_data", "deleteMasterSubDocType", masterSubDocKey); },
    loadMasterDocTypes: (): Promise<Array<UIAdminMasterDocType>> => { return this.connector.rpcCall("admin_master_data", "loadMasterDocTypes", null); },
    loadMasterHolidays: (): Promise<Array<UIAdminMasterHoliday>> => { return this.connector.rpcCall("admin_master_data", "loadMasterHolidays", null); },
    loadMasterIndexingFields: (): Promise<Array<UIAdminMasterIndexingField>> => { return this.connector.rpcCall("admin_master_data", "loadMasterIndexingFields", null); },
    loadMasterLabels: (): Promise<Array<UIAdminMasterLabel>> => { return this.connector.rpcCall("admin_master_data", "loadMasterLabels", null); },
    loadMasterSubDocTypes: (): Promise<Array<UIAdminMasterSubDocType>> => { return this.connector.rpcCall("admin_master_data", "loadMasterSubDocTypes", null); },
    loadStandardOptionList: (payload: {standardOptionList:MasterIndexingFieldRow_StandardOptionList}): Promise<UIAdminStandardOptionList> => { return this.connector.rpcCall("admin_master_data", "loadStandardOptionList", payload); },
    loadStandardOptionLists: (): Promise<Array<UIAdminStandardOptionList>> => { return this.connector.rpcCall("admin_master_data", "loadStandardOptionLists", null); },
    reorderMasterIndexingField: (payload: {fromFieldOrder:number,toFieldOrder:number}): Promise<void> => { return this.connector.rpcCall("admin_master_data", "reorderMasterIndexingField", payload); },
    updateMasterDocType: (docType: UIAdminMasterDocType): Promise<UIAdminMasterDocType> => { return this.connector.rpcCall("admin_master_data", "updateMasterDocType", docType); },
    updateMasterHoliday: (holiday: UIAdminMasterHoliday): Promise<void> => { return this.connector.rpcCall("admin_master_data", "updateMasterHoliday", holiday); },
    updateMasterIndexingField: (indexingField: UIAdminMasterIndexingField): Promise<UIAdminMasterIndexingField> => { return this.connector.rpcCall("admin_master_data", "updateMasterIndexingField", indexingField); },
    updateMasterLabel: (label: UIAdminMasterLabel): Promise<UIAdminMasterLabel> => { return this.connector.rpcCall("admin_master_data", "updateMasterLabel", label); },
    updateMasterSubDocType: (subDocType: UIAdminMasterSubDocType): Promise<void> => { return this.connector.rpcCall("admin_master_data", "updateMasterSubDocType", subDocType); },
    observe: {
      loadMasterDocTypes: (): Observable<Array<UIAdminMasterDocType>> => { return this.ws.rpcSubscribe("admin_master_data", "loadMasterDocTypes", null); },
      loadMasterHolidays: (): Observable<Array<UIAdminMasterHoliday>> => { return this.ws.rpcSubscribe("admin_master_data", "loadMasterHolidays", null); },
      loadMasterIndexingFields: (): Observable<Array<UIAdminMasterIndexingField>> => { return this.ws.rpcSubscribe("admin_master_data", "loadMasterIndexingFields", null); },
      loadMasterLabels: (): Observable<Array<UIAdminMasterLabel>> => { return this.ws.rpcSubscribe("admin_master_data", "loadMasterLabels", null); },
      loadMasterSubDocTypes: (): Observable<Array<UIAdminMasterSubDocType>> => { return this.ws.rpcSubscribe("admin_master_data", "loadMasterSubDocTypes", null); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_news | Class co.coolhc.hopdox.handler.AdminNewsHandler
  admin_news = {
    deleteNews: (payload: {newsId:number}): Promise<void> => { return this.connector.rpcCall("admin_news", "deleteNews", payload); },
    loadAllNews: (): Promise<Array<UIAdminNews>> => { return this.connector.rpcCall("admin_news", "loadAllNews", null); },
    updateNews: (payload: {uiAdminNews:UIAdminNews}): Promise<void> => { return this.connector.rpcCall("admin_news", "updateNews", payload); },
    observe: {
      loadAllNews: (): Observable<Array<UIAdminNews>> => { return this.ws.rpcSubscribe("admin_news", "loadAllNews", null); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_notifications | Class co.coolhc.hopdox.handler.AdminNotificationHandler
  admin_notifications = {
    loadAllRecentNotifications: (): Promise<Array<UIAdminNotification>> => { return this.connector.rpcCall("admin_notifications", "loadAllRecentNotifications", null); },
    resendNotification: (payload: {notification:UIAdminNotification}): Promise<void> => { return this.connector.rpcCall("admin_notifications", "resendNotification", payload); },
    observe: {
      loadAllRecentNotifications: (): Observable<Array<UIAdminNotification>> => { return this.ws.rpcSubscribe("admin_notifications", "loadAllRecentNotifications", null); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_orgs | Class co.coolhc.hopdox.handler.AdminOrganizationHandler
  admin_orgs = {
    createOrg: (editableOrg: UIEditableOrganization): Promise<UIOrgInfo> => { return this.connector.rpcCall("admin_orgs", "createOrg", editableOrg); },
    deleteOrg: (key: string): Promise<boolean> => { return this.connector.rpcCall("admin_orgs", "deleteOrg", key); },
    editOrg: (editableOrg: UIEditableOrganization): Promise<UIEditableOrganization> => { return this.connector.rpcCall("admin_orgs", "editOrg", editableOrg); },
    loadOrg: (key: string): Promise<UIOrgInfo> => { return this.connector.rpcCall("admin_orgs", "loadOrg", key); },
    loadOrgList: (search: UIOrgList_Search): Promise<UIOrgList> => { return this.connector.rpcCall("admin_orgs", "loadOrgList", search); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_payments | Class co.coolhc.hopdox.handler.AdminPaymentsHandler
  admin_payments = {
    cancelCharge: (chargeId: FnChargeRow_FnChargeId): Promise<void> => { return this.connector.rpcCall("admin_payments", "cancelCharge", chargeId); },
    createManualTransaction: (create: UIAdminCreateManualTransaction): Promise<void> => { return this.connector.rpcCall("admin_payments", "createManualTransaction", create); },
    downloadACHFile: (achFileId: FnAchFileRow_FnAchFileId): Promise<string> => { return this.connector.rpcCall("admin_payments", "downloadACHFile", achFileId); },
    loadACHLines: (payload: {startDate:string,endDate:string,detailLevel:FinancialModels_PaymentDetailLevel}): Promise<Array<UIACHPaymentGroup>> => { return this.connector.rpcCall("admin_payments", "loadACHLines", payload); },
    loadAvailableAccountsByEntity: (entityKey: string): Promise<UIAdminStandardOptionList> => { return this.connector.rpcCall("admin_payments", "loadAvailableAccountsByEntity", entityKey); },
    loadAvailableEntities: (): Promise<UIAdminStandardOptionList> => { return this.connector.rpcCall("admin_payments", "loadAvailableEntities", null); },
    processACHPayments: (payload: {bankIntegration:FnAchBankRow_BankIntegration,payments:Array<FnPaymentRow_FnPaymentId>}): Promise<void> => { return this.connector.rpcCall("admin_payments", "processACHPayments", payload); },
    updatePaymentSchedule: (payments: Array<FnPaymentRow_FnPaymentId>): Promise<void> => { return this.connector.rpcCall("admin_payments", "updatePaymentSchedule", payments); },
    uploadACHFile: (achFileId: FnAchFileRow_FnAchFileId): Promise<boolean> => { return this.connector.rpcCall("admin_payments", "uploadACHFile", achFileId); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_recipient_integrations | Class co.coolhc.hopdox.handler.AdminRecipientIntegrationHandler
  admin_recipient_integrations = {
    addContextData: (payload: {integrationKey:string,contextLabel:string}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "addContextData", payload); },
    addRecipientIntegrationOperation: (payload: {key:string,operation:string}): Promise<string> => { return this.connector.rpcCall("admin_recipient_integrations", "addRecipientIntegrationOperation", payload); },
    createIntegration: (payload: {name:string}): Promise<UIAdminRecipientIntegration> => { return this.connector.rpcCall("admin_recipient_integrations", "createIntegration", payload); },
    deleteContextData: (payload: {integrationKey:string,contextLabel:string}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "deleteContextData", payload); },
    deleteContextLibary: (payload: {libraryName:string}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "deleteContextLibary", payload); },
    deleteIntegration: (payload: {key:string}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "deleteIntegration", payload); },
    deleteRecipientIntegrationOperation: (payload: {key:string,operation:string}): Promise<string> => { return this.connector.rpcCall("admin_recipient_integrations", "deleteRecipientIntegrationOperation", payload); },
    exportRecipientIntegrationCSV: (key: string): Promise<string> => { return this.connector.rpcCall("admin_recipient_integrations", "exportRecipientIntegrationCSV", key); },
    generateCertificate: (payload: {key:string,encryptionId:string}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "generateCertificate", payload); },
    importSoapUIProject: (payload: {key:string,soapUiXml:string}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "importSoapUIProject", payload); },
    loadContextData: (payload: {integrationKey:string,contextLabel:string}): Promise<UIAdminRecipientIntegrationContextData> => { return this.connector.rpcCall("admin_recipient_integrations", "loadContextData", payload); },
    loadContextLabels: (integrationKey: string): Promise<Array<string>> => { return this.connector.rpcCall("admin_recipient_integrations", "loadContextLabels", integrationKey); },
    loadContextLibary: (payload: {libraryName:string}): Promise<UIAdminRecipientIntegrationContextLibraryData> => { return this.connector.rpcCall("admin_recipient_integrations", "loadContextLibary", payload); },
    loadContextLibraries: (): Promise<Array<string>> => { return this.connector.rpcCall("admin_recipient_integrations", "loadContextLibraries", null); },
    loadIntegrationsList: (search: UIAdminRecipientIntegrationList_Search): Promise<UIAdminRecipientIntegrationList> => { return this.connector.rpcCall("admin_recipient_integrations", "loadIntegrationsList", search); },
    loadRecipientIntegration: (key: string): Promise<UIAdminRecipientIntegration> => { return this.connector.rpcCall("admin_recipient_integrations", "loadRecipientIntegration", key); },
    loadRecipientIntegrationOperations: (key: string): Promise<Array<AdminRecipientIntegrationHandler_RecipientIntegrationOperation>> => { return this.connector.rpcCall("admin_recipient_integrations", "loadRecipientIntegrationOperations", key); },
    loadSpecificTemplate: (payload: {key:string,integrationOp:UIAdminRecipientIntegration_IntegrationOp,renderStage:IntegrationOperationBusiness_RenderStage}): Promise<string> => { return this.connector.rpcCall("admin_recipient_integrations", "loadSpecificTemplate", payload); },
    performRequestOperation: (payload: {integration:UIAdminRecipientIntegration,operationName:string,soapEnvelopeOrRESTContent:string}): Promise<AdminRecipientIntegrationHandler_MultiTypeResponse> => { return this.connector.rpcCall("admin_recipient_integrations", "performRequestOperation", payload); },
    performResponseOperation: (payload: {integration:UIAdminRecipientIntegration,contextData:UIAdminRecipientIntegrationContextData,operationName:string}): Promise<AdminRecipientIntegrationHandler_ResponseOperationResult> => { return this.connector.rpcCall("admin_recipient_integrations", "performResponseOperation", payload); },
    renameContextData: (payload: {integrationKey:string,newContextLabel:string,oldContextLabel:string}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "renameContextData", payload); },
    renameContextLibary: (payload: {newLibraryName:string,oldLibraryName:string}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "renameContextLibary", payload); },
    renameRecipientIntegrationOperation: (payload: {key:string,oldOperation:string,newOperation:string}): Promise<string> => { return this.connector.rpcCall("admin_recipient_integrations", "renameRecipientIntegrationOperation", payload); },
    renderTemplateWithContext: (payload: {integration:UIAdminRecipientIntegration,context:string,operationName:string,renderStage:IntegrationOperationBusiness_RenderStage,pushThroughImagen:boolean}): Promise<AdminRecipientIntegrationHandler_MultiTypeResponse> => { return this.connector.rpcCall("admin_recipient_integrations", "renderTemplateWithContext", payload); },
    saveContextData: (payload: {integrationKey:string,contextData:UIAdminRecipientIntegrationContextData}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "saveContextData", payload); },
    saveContextLibrary: (payload: {contextData:UIAdminRecipientIntegrationContextLibraryData}): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "saveContextLibrary", payload); },
    saveRecipientIntegration: (integration: UIAdminRecipientIntegration): Promise<void> => { return this.connector.rpcCall("admin_recipient_integrations", "saveRecipientIntegration", integration); },
    observe: {
      loadContextLabels: (integrationKey: string): Observable<Array<string>> => { return this.ws.rpcSubscribe("admin_recipient_integrations", "loadContextLabels", integrationKey); },
      loadContextLibraries: (): Observable<Array<string>> => { return this.ws.rpcSubscribe("admin_recipient_integrations", "loadContextLibraries", null); },
      loadIntegrationsList: (search: UIAdminRecipientIntegrationList_Search): Observable<UIAdminRecipientIntegrationList> => { return this.ws.rpcSubscribe("admin_recipient_integrations", "loadIntegrationsList", search); },
      loadRecipientIntegration: (key: string): Observable<UIAdminRecipientIntegration> => { return this.ws.rpcSubscribe("admin_recipient_integrations", "loadRecipientIntegration", key); },
      loadRecipientIntegrationOperations: (key: string): Observable<Array<AdminRecipientIntegrationHandler_RecipientIntegrationOperation>> => { return this.ws.rpcSubscribe("admin_recipient_integrations", "loadRecipientIntegrationOperations", key); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_recipient_test_indexing | Class co.coolhc.hopdox.handler.AdminRecipientTestIndexingHandler
  admin_recipient_test_indexing = {
    getOptionsList: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,externalDocType:string,optionLists:Array<string>}): Promise<Array<ERRecipientOptionList>> => { return this.connector.rpcCall("admin_recipient_test_indexing", "getOptionsList", payload); },
    getSampleFields: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,externalDocType:string}): Promise<Array<ERRecipientIndexingField>> => { return this.connector.rpcCall("admin_recipient_test_indexing", "getSampleFields", payload); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_recipients | Class co.coolhc.hopdox.handler.AdminRecipientHandler
  admin_recipients = {
    addLandRecordVendor: (payload: {name:string,key:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addLandRecordVendor", payload); },
    addRecipientClosure: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,recipientClosure:UIAdminRecipientClosure}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientClosure", payload); },
    addRecipientDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,docTypes:Array<UIAdminRecipientDocType>}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientDocTypes", payload); },
    addRecipientFeecalcCondition: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcCondition:UIAdminRecipientFeecalcCondition}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientFeecalcCondition", payload); },
    addRecipientFeecalcDocTarget: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcDocTarget:UIAdminRecipientFeecalcDocTarget}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientFeecalcDocTarget", payload); },
    addRecipientFeecalcFee: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcFee:UIAdminRecipientFeecalcFee}): Promise<UIAdminRecipientFeecalcFee> => { return this.connector.rpcCall("admin_recipients", "addRecipientFeecalcFee", payload); },
    addRecipientFeecalcInput: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcInput:UIAdminRecipientFeecalcInput}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientFeecalcInput", payload); },
    addRecipientHelperDocType: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,helperDocType:UIAdminRecipientHelperDocType}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientHelperDocType", payload); },
    addRecipientHoliday: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,holiday:UIAdminRecipientHoliday}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientHoliday", payload); },
    addRecipientHours: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,recipientHours:UIAdminRecipientHours}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientHours", payload); },
    addRecipientIndexingFields: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,paths:Array<string>}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientIndexingFields", payload); },
    addRecipientInstruction: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,uiAdminRecipientInstruction:UIAdminRecipientInstruction}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientInstruction", payload); },
    addRecipientMarginSetting: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,marginSettings:UIAdminRecipientMarginSettings}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientMarginSetting", payload); },
    addRecipientMasterDocType: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,docType:UIAdminRecipientMasterDocType}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientMasterDocType", payload); },
    addRecipientOptionList: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,optionList:UIAdminRecipientOptionList}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "addRecipientOptionList", payload); },
    autoMapRecipientDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,recipientDocTypes:Array<UIAdminRecipientDocType>}): Promise<Array<UIAutoMappedRecipientType>> => { return this.connector.rpcCall("admin_recipients", "autoMapRecipientDocTypes", payload); },
    copyFeesFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKey:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copyFeesFromRecipient", payload); },
    copyGenericCountyConfig: (ui: AdminRecipientHandler_UIAdminCopyGenericCountyConfigModel): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copyGenericCountyConfig", ui); },
    copyHolidaysFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKey:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copyHolidaysFromRecipient", payload); },
    copyHoursFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKey:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copyHoursFromRecipient", payload); },
    copyInstructionsFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKey:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copyInstructionsFromRecipient", payload); },
    copyMarginSettingsFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKey:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copyMarginSettingsFromRecipient", payload); },
    copySingleFeeFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKeys:Array<string>,feeId:number}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copySingleFeeFromRecipient", payload); },
    copySingleHolidayFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKeys:Array<string>,holidayKey:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copySingleHolidayFromRecipient", payload); },
    copySingleHoursSetFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKeys:Array<string>,hoursId:number}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copySingleHoursSetFromRecipient", payload); },
    copySingleInstructionFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKeys:Array<string>,instructionId:number}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copySingleInstructionFromRecipient", payload); },
    copySingleMarginFromRecipient: (payload: {sourceRecipientKey:string,targetRecipientKeys:Array<string>,marginKey:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "copySingleMarginFromRecipient", payload); },
    createRecipient: (payload: {name:string}): Promise<UIAdminRecipientInfo> => { return this.connector.rpcCall("admin_recipients", "createRecipient", payload); },
    createRecipientFromJurisdiction: (payload: {priaRJID:string}): Promise<UIAdminRecipientInfo> => { return this.connector.rpcCall("admin_recipients", "createRecipientFromJurisdiction", payload); },
    deleteLandRecordVendor: (payload: {vendorId:number}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "deleteLandRecordVendor", payload); },
    deleteRecipient: (payload: {key:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "deleteRecipient", payload); },
    diffRecipientLiveConfigHistories: (payload: {recipientKey:string,recipientLiveConfigHistoryId1:number,recipientLiveConfigHistoryId2:number}): Promise<UIAdminDataImport> => { return this.connector.rpcCall("admin_recipients", "diffRecipientLiveConfigHistories", payload); },
    exportRecipientCSV: (key: string): Promise<string> => { return this.connector.rpcCall("admin_recipients", "exportRecipientCSV", key); },
    getRecipientDocTypesFromVendor: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<RecipientDocTypeListResponse> => { return this.connector.rpcCall("admin_recipients", "getRecipientDocTypesFromVendor", payload); },
    loadLandRecordVendorList: (): Promise<Array<AdminRecipientHandler_UILandRecordVendor>> => { return this.connector.rpcCall("admin_recipients", "loadLandRecordVendorList", null); },
    loadRecipient: (key: string): Promise<UIAdminRecipientInfo> => { return this.connector.rpcCall("admin_recipients", "loadRecipient", key); },
    loadRecipientClosures: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientClosure>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientClosures", payload); },
    loadRecipientConfigHistory: (payload: {recipientKey:string}): Promise<Array<UIAdminRecipientConfigHistory>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientConfigHistory", payload); },
    loadRecipientDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientDocType>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientDocTypes", payload); },
    loadRecipientFeecalcConditions: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientFeecalcCondition>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientFeecalcConditions", payload); },
    loadRecipientFeecalcDocTargets: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientFeecalcDocTarget>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientFeecalcDocTargets", payload); },
    loadRecipientFeecalcFees: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientFeecalcFee>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientFeecalcFees", payload); },
    loadRecipientFeecalcInputs: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientFeecalcInput>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientFeecalcInputs", payload); },
    loadRecipientHelperDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientHelperDocType>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientHelperDocTypes", payload); },
    loadRecipientHelperMasterDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientMasterDocType>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientHelperMasterDocTypes", payload); },
    loadRecipientHolidays: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientHoliday>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientHolidays", payload); },
    loadRecipientHours: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientHours>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientHours", payload); },
    loadRecipientIndexingFields: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientIndexingField>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientIndexingFields", payload); },
    loadRecipientInstructions: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientInstruction>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientInstructions", payload); },
    loadRecipientIntegrationConfig: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<UIAdminRecipientIntegrationConfig> => { return this.connector.rpcCall("admin_recipients", "loadRecipientIntegrationConfig", payload); },
    loadRecipientList: (search: UIAdminRecipientList_Search): Promise<UIAdminRecipientList> => { return this.connector.rpcCall("admin_recipients", "loadRecipientList", search); },
    loadRecipientMarginSettings: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientMarginSettings>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientMarginSettings", payload); },
    loadRecipientMasterDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientMasterDocType>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientMasterDocTypes", payload); },
    loadRecipientOptionLists: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<Array<UIAdminRecipientOptionList>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientOptionLists", payload); },
    loadRecipientPaymentSettings: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Promise<UIAdminRecipientPaymentSettings> => { return this.connector.rpcCall("admin_recipients", "loadRecipientPaymentSettings", payload); },
    loadRecipientsByLandRecordVendor: (payload: {vendorId:number}): Promise<Array<UIAdminRecipientInfo>> => { return this.connector.rpcCall("admin_recipients", "loadRecipientsByLandRecordVendor", payload); },
    loadRecordingJurisdictions: (): Promise<Array<UIAdminRecordingJurisdictionInfo_StateGrouping>> => { return this.connector.rpcCall("admin_recipients", "loadRecordingJurisdictions", null); },
    loadStateRecordingJurisdictions: (stateFips: string): Promise<Array<UIAdminRecordingJurisdictionInfo>> => { return this.connector.rpcCall("admin_recipients", "loadStateRecordingJurisdictions", stateFips); },
    loadStates: (): Promise<Array<UIUSState>> => { return this.connector.rpcCall("admin_recipients", "loadStates", null); },
    prepareToPublishTestConfigToLiveConfig: (payload: {recipientKey:string}): Promise<UIAdminDataImport> => { return this.connector.rpcCall("admin_recipients", "prepareToPublishTestConfigToLiveConfig", payload); },
    publishTestConfigToLiveConfig: (payload: {recipientKey:string,publishComment:string,preparedImport:UIAdminDataImport}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "publishTestConfigToLiveConfig", payload); },
    reactivateLandRecordVendor: (payload: {vendorId:number}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "reactivateLandRecordVendor", payload); },
    removeRecipientClosure: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,recipientClosure:UIAdminRecipientClosure}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientClosure", payload); },
    removeRecipientDocType: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,externalDocType:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientDocType", payload); },
    removeRecipientFeecalcCondition: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcCondition:UIAdminRecipientFeecalcCondition}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientFeecalcCondition", payload); },
    removeRecipientFeecalcFee: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcFee:UIAdminRecipientFeecalcFee}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientFeecalcFee", payload); },
    removeRecipientFeecalcInput: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcInput:UIAdminRecipientFeecalcInput}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientFeecalcInput", payload); },
    removeRecipientHelperDocType: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,externalDocType:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientHelperDocType", payload); },
    removeRecipientHoliday: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,holiday:UIAdminRecipientHoliday}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientHoliday", payload); },
    removeRecipientHours: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,recipientHours:UIAdminRecipientHours}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientHours", payload); },
    removeRecipientIndexingField: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,path:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientIndexingField", payload); },
    removeRecipientInstruction: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,uiAdminRecipientInstruction:UIAdminRecipientInstruction}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientInstruction", payload); },
    removeRecipientMarginSetting: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,marginSettings:UIAdminRecipientMarginSettings}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientMarginSetting", payload); },
    removeRecipientMasterDocType: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,docType:UIAdminRecipientMasterDocType}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientMasterDocType", payload); },
    removeRecipientOptionList: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,optionList:UIAdminRecipientOptionList}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "removeRecipientOptionList", payload); },
    reorderRecipientFeecalcFeeSeq: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,fromFeeSeq:number,toFeeSeq:number}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "reorderRecipientFeecalcFeeSeq", payload); },
    reorderRecipientInstruction: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,fromOrder:number,toOrder:number}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "reorderRecipientInstruction", payload); },
    updateLandRecordVendor: (payload: {uiVendor:AdminRecipientHandler_UILandRecordVendor}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateLandRecordVendor", payload); },
    updateListRecipientSelectedMasterDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,updatedRecipientMasterTypesList:Array<UIAdminRecipientMasterTypesUpdate>}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateListRecipientSelectedMasterDocTypes", payload); },
    updateRecipientClosure: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,recipientClosure:UIAdminRecipientClosure}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientClosure", payload); },
    updateRecipientDocType: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,docType:UIAdminRecipientDocType}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientDocType", payload); },
    updateRecipientFeecalcCondition: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcCondition:UIAdminRecipientFeecalcCondition}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientFeecalcCondition", payload); },
    updateRecipientFeecalcDocTargetsForFee: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,newFeecalcDocTargets:Array<UIAdminRecipientFeecalcDocTarget>}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientFeecalcDocTargetsForFee", payload); },
    updateRecipientFeecalcFee: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcFee:UIAdminRecipientFeecalcFee}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientFeecalcFee", payload); },
    updateRecipientFeecalcInput: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,feecalcInput:UIAdminRecipientFeecalcInput}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientFeecalcInput", payload); },
    updateRecipientHelperDocType: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,helperDocType:UIAdminRecipientHelperDocType}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientHelperDocType", payload); },
    updateRecipientHoliday: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,holiday:UIAdminRecipientHoliday}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientHoliday", payload); },
    updateRecipientHours: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,recipientHours:UIAdminRecipientHours}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientHours", payload); },
    updateRecipientIndexingField: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,indexingField:UIAdminRecipientIndexingField}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientIndexingField", payload); },
    updateRecipientInfo: (payload: {recipientInfo:UIAdminRecipientInfo}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientInfo", payload); },
    updateRecipientInstruction: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,uiAdminRecipientInstruction:UIAdminRecipientInstruction}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientInstruction", payload); },
    updateRecipientIntegrationConfig: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,integrationConfig:UIAdminRecipientIntegrationConfig}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientIntegrationConfig", payload); },
    updateRecipientMarginSetting: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,marginSettings:UIAdminRecipientMarginSettings}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientMarginSetting", payload); },
    updateRecipientMasterDocType: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,docType:UIAdminRecipientMasterDocType}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientMasterDocType", payload); },
    updateRecipientOptionList: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,optionList:UIAdminRecipientOptionList}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientOptionList", payload); },
    updateRecipientPaymentSettings: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,paymentSettings:UIAdminRecipientPaymentSettings}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientPaymentSettings", payload); },
    updateRecipientSelectedHelperMasterDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,selectedMasterDocTypes:Array<string>,selectedMasterSubDocTypes:Array<string>,externalDocType:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientSelectedHelperMasterDocTypes", payload); },
    updateRecipientSelectedMasterDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode,selectedMasterDocTypes:Array<string>,selectedMasterSubDocTypes:Array<string>,externalDocType:string}): Promise<void> => { return this.connector.rpcCall("admin_recipients", "updateRecipientSelectedMasterDocTypes", payload); },
    observe: {
      loadLandRecordVendorList: (): Observable<Array<AdminRecipientHandler_UILandRecordVendor>> => { return this.ws.rpcSubscribe("admin_recipients", "loadLandRecordVendorList", null); },
      loadRecipient: (key: string): Observable<UIAdminRecipientInfo> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipient", key); },
      loadRecipientClosures: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientClosure>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientClosures", payload); },
      loadRecipientDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientDocType>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientDocTypes", payload); },
      loadRecipientFeecalcConditions: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientFeecalcCondition>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientFeecalcConditions", payload); },
      loadRecipientFeecalcDocTargets: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientFeecalcDocTarget>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientFeecalcDocTargets", payload); },
      loadRecipientFeecalcFees: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientFeecalcFee>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientFeecalcFees", payload); },
      loadRecipientFeecalcInputs: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientFeecalcInput>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientFeecalcInputs", payload); },
      loadRecipientHelperDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientHelperDocType>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientHelperDocTypes", payload); },
      loadRecipientHelperMasterDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientMasterDocType>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientHelperMasterDocTypes", payload); },
      loadRecipientHolidays: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientHoliday>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientHolidays", payload); },
      loadRecipientHours: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientHours>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientHours", payload); },
      loadRecipientIndexingFields: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientIndexingField>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientIndexingFields", payload); },
      loadRecipientInstructions: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientInstruction>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientInstructions", payload); },
      loadRecipientIntegrationConfig: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<UIAdminRecipientIntegrationConfig> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientIntegrationConfig", payload); },
      loadRecipientList: (search: UIAdminRecipientList_Search): Observable<UIAdminRecipientList> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientList", search); },
      loadRecipientMarginSettings: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientMarginSettings>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientMarginSettings", payload); },
      loadRecipientMasterDocTypes: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientMasterDocType>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientMasterDocTypes", payload); },
      loadRecipientOptionLists: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<Array<UIAdminRecipientOptionList>> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientOptionLists", payload); },
      loadRecipientPaymentSettings: (payload: {recipientKey:string,mode:AdminRecipientHandler_Mode}): Observable<UIAdminRecipientPaymentSettings> => { return this.ws.rpcSubscribe("admin_recipients", "loadRecipientPaymentSettings", payload); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace admin_users | Class co.coolhc.hopdox.handler.AdminUserHandler
  admin_users = {
    deleteUser: (userKey: string): Promise<void> => { return this.connector.rpcCall("admin_users", "deleteUser", userKey); },
    deleteUserInvite: (email: string): Promise<void> => { return this.connector.rpcCall("admin_users", "deleteUserInvite", email); },
    editUser: (uiUser: UIUserInfo): Promise<void> => { return this.connector.rpcCall("admin_users", "editUser", uiUser); },
    getOrgUsersAndRoles: (): Promise<Array<UIUserInfo>> => { return this.connector.rpcCall("admin_users", "getOrgUsersAndRoles", null); },
    inviteUser: (inviteUser: UIInviteUser): Promise<void> => { return this.connector.rpcCall("admin_users", "inviteUser", inviteUser); },
    loadUser: (key: string): Promise<UIAdminUserInfo> => { return this.connector.rpcCall("admin_users", "loadUser", key); },
    loadUserList: (search: UIUserList_Search): Promise<UIUserList> => { return this.connector.rpcCall("admin_users", "loadUserList", search); },
    loadUsersByOrg: (orgKey: string): Promise<Array<UIAdminUserInfo>> => { return this.connector.rpcCall("admin_users", "loadUsersByOrg", orgKey); },
    registerNewUserForOrg: (registerUser: UIAdminRegisterUser): Promise<UIAdminUserInfo> => { return this.connector.rpcCall("admin_users", "registerNewUserForOrg", registerUser); },
    updateUser: (payload: {uiUser:UIAdminUserInfo}): Promise<void> => { return this.connector.rpcCall("admin_users", "updateUser", payload); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace construction_projects | Class co.coolhc.hopdox.handler.ConstructionProjectHandler
  construction_projects = {
    getConstructionProject: (key: string): Promise<UIEditableConstructionProject> => { return this.connector.rpcCall("construction_projects", "getConstructionProject", key); },
    getConstructionProjectRequirement: (): Promise<UIEditableCPRequirements> => { return this.connector.rpcCall("construction_projects", "getConstructionProjectRequirement", null); },
    getNocPreviewLink: (key: string): Promise<UIThumbnailLink> => { return this.connector.rpcCall("construction_projects", "getNocPreviewLink", key); },
    loadConstructionProjectList: (search: UIConstructionProjectList_Search): Promise<UIConstructionProjectList> => { return this.connector.rpcCall("construction_projects", "loadConstructionProjectList", search); },
    onSendToOwnerPage: (key: string): Promise<void> => { return this.connector.rpcCall("construction_projects", "onSendToOwnerPage", key); },
    saveProject: (editableProject: UIEditableConstructionProject): Promise<UIEditableConstructionProject> => { return this.connector.rpcCall("construction_projects", "saveProject", editableProject); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace docRecognition | Class co.coolhc.hopdox.handler.DocRecognitionHandler
  docRecognition = {
    uploadAndAnalyze: (uploadFiles: Array<UIFile>): Promise<{ [key: string]: any}> => { return this.connector.rpcCall("docRecognition", "uploadAndAnalyze", uploadFiles); },
    observe: {
    },
    with_updates: {
      uploadAndAnalyze: (uploadFiles: Array<UIFile>): [Observable<Array<any>>,Promise<{ [key: string]: any}>] => { return this.ws.rpcCallWithUpdates("docRecognition", "uploadAndAnalyze", uploadFiles); }
    }
  }
  // ---

  // ---> Namespace er_doc_data_extraction | Class co.coolhc.hopdox.handler.ErDocDataExtractionHandler
  er_doc_data_extraction = {
    applyExtractedData: (payload: {uploadKey:string}): Promise<ErDocDataExtractionHandler_StatusData> => { return this.connector.rpcCall("er_doc_data_extraction", "applyExtractedData", payload); },
    attachIndexingOcrData: (payload: {uploadKey:string}): Promise<void> => { return this.connector.rpcCall("er_doc_data_extraction", "attachIndexingOcrData", payload); },
    attachIndexingOcrDataToDoc: (payload: {docKey:string}): Promise<void> => { return this.connector.rpcCall("er_doc_data_extraction", "attachIndexingOcrDataToDoc", payload); },
    getDataExtractionStatus: (payload: {uploadKey:string}): Promise<ErDocDataExtractionHandler_StatusData> => { return this.connector.rpcCall("er_doc_data_extraction", "getDataExtractionStatus", payload); },
    rerunDataExtraction: (payload: {uploadKey:string}): Promise<ErDocDataExtractionHandler_StatusData> => { return this.connector.rpcCall("er_doc_data_extraction", "rerunDataExtraction", payload); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace er_packages | Class co.coolhc.hopdox.handler.ErPackageListsHandler
  er_packages = {
    getPackageList: (payload: {workspace:UIERPackageView}): Promise<UIERPackageList> => { return this.connector.rpcCall("er_packages", "getPackageList", payload); },
    getUserWorkspaces: (): Promise<Array<ErPackageListsHandler_DefaultWorkSpace>> => { return this.connector.rpcCall("er_packages", "getUserWorkspaces", null); },
    getWorkspace: (payload: {workspaceKey:string}): Promise<UIERPackageView> => { return this.connector.rpcCall("er_packages", "getWorkspace", payload); },
    observe: {
      getPackageList: (payload: {workspace:UIERPackageView}): Observable<UIERPackageList> => { return this.ws.rpcSubscribe("er_packages", "getPackageList", payload); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace er_reports | Class co.coolhc.hopdox.handler.ErReportsHandler
  er_reports = {
    getPackageReport: (payload: {workspace:UIERWorkspace}): Promise<UIERReport> => { return this.connector.rpcCall("er_reports", "getPackageReport", payload); },
    getUserWorkspaces: (): Promise<Array<ErReportsHandler_DefaultWorkSpace>> => { return this.connector.rpcCall("er_reports", "getUserWorkspaces", null); },
    getWorkspace: (payload: {workspaceKey:string}): Promise<UIERWorkspace> => { return this.connector.rpcCall("er_reports", "getWorkspace", payload); },
    queryReportCharges: (payload: {workspace:UIERWorkspace}): Promise<UIERReport> => { return this.connector.rpcCall("er_reports", "queryReportCharges", payload); },
    queryReportEstimates: (payload: {workspace:UIERWorkspace}): Promise<UIERReport> => { return this.connector.rpcCall("er_reports", "queryReportEstimates", payload); },
    observe: {
      getPackageReport: (payload: {workspace:UIERWorkspace}): Observable<UIERReport> => { return this.ws.rpcSubscribe("er_reports", "getPackageReport", payload); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace er_submitter | Class co.coolhc.hopdox.handler.ErSubmitterHandler
  er_submitter = {
    archivePackage: (payload: {packageKey:string}): Promise<void> => { return this.connector.rpcCall("er_submitter", "archivePackage", payload); },
    changeDocumentName: (payload: {documentKey:string,name:string}): Promise<void> => { return this.connector.rpcCall("er_submitter", "changeDocumentName", payload); },
    createPackage: (payload: {orgKey:string,packageName:string}): Promise<string> => { return this.connector.rpcCall("er_submitter", "createPackage", payload); },
    deleteDocument: (payload: {documentKey:string}): Promise<void> => { return this.connector.rpcCall("er_submitter", "deleteDocument", payload); },
    deleteHelperDocument: (payload: {helperDocumentKey:string}): Promise<void> => { return this.connector.rpcCall("er_submitter", "deleteHelperDocument", payload); },
    deletePackage: (payload: {packageKey:string}): Promise<void> => { return this.connector.rpcCall("er_submitter", "deletePackage", payload); },
    editPackage: (editablePackage: UIEditableERPackage): Promise<void> => { return this.connector.rpcCall("er_submitter", "editPackage", editablePackage); },
    getDocumentAnalysis: (documentKeys: Array<string>): Promise<Array<UIERDocumentAnalysis>> => { return this.connector.rpcCall("er_submitter", "getDocumentAnalysis", documentKeys); },
    getPackageDetails: (payload: {packageKey:string}): Promise<UIERPackage> => { return this.connector.rpcCall("er_submitter", "getPackageDetails", payload); },
    loadPackageHistory: (payload: {orgKey:string,packageKey:string}): Promise<Array<UIERHistoryEvent>> => { return this.connector.rpcCall("er_submitter", "loadPackageHistory", payload); },
    loadRecipientInfo: (key: number): Promise<UISubmitterRecipientInfo> => { return this.connector.rpcCall("er_submitter", "loadRecipientInfo", key); },
    loadRecipientStatusList: (): Promise<Array<ErSubmitterHandler_RecipientState>> => { return this.connector.rpcCall("er_submitter", "loadRecipientStatusList", null); },
    loadSubmitterRecipients: (payload: {orgKey:string,packageKey:string,recipientSearch:string}): Promise<Array<UIERSubmitterRecipient>> => { return this.connector.rpcCall("er_submitter", "loadSubmitterRecipients", payload); },
    markPackageViewed: (payload: {packageKey:string}): Promise<void> => { return this.connector.rpcCall("er_submitter", "markPackageViewed", payload); },
    reorderDocuments: (document: UIERDocument): Promise<void> => { return this.connector.rpcCall("er_submitter", "reorderDocuments", document); },
    submitPackage: (packageKey: string): Promise<void> => { return this.connector.rpcCall("er_submitter", "submitPackage", packageKey); },
    unarchivePackage: (payload: {packageKey:string}): Promise<void> => { return this.connector.rpcCall("er_submitter", "unarchivePackage", payload); },
    observe: {
      getDocumentAnalysis: (documentKeys: Array<string>): Observable<Array<UIERDocumentAnalysis>> => { return this.ws.rpcSubscribe("er_submitter", "getDocumentAnalysis", documentKeys); },
      getPackageDetails: (payload: {packageKey:string}): Observable<UIERPackage> => { return this.ws.rpcSubscribe("er_submitter", "getPackageDetails", payload); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace er_submitter_docviewer | Class co.coolhc.hopdox.handler.ErSubmitterDocviewerHandler
  er_submitter_docviewer = {
    deleteDocPages: (payload: {packageKey:string,pageKeys:Array<string>}): Promise<void> => { return this.connector.rpcCall("er_submitter_docviewer", "deleteDocPages", payload); },
    findViableDoc: (payload: {submitterKey:string}): Promise<string> => { return this.connector.rpcCall("er_submitter_docviewer", "findViableDoc", payload); },
    forTypeInformation: (payload: {arg1:ErIndexingData}): Promise<void> => { return this.connector.rpcCall("er_submitter_docviewer", "forTypeInformation", payload); },
    getBasicDocPages: (payload: {documentKey:string}): Promise<Array<ImagenModels_AlteredPage>> => { return this.connector.rpcCall("er_submitter_docviewer", "getBasicDocPages", payload); },
    getDocumentViewerSummary: (documentKey: string): Promise<ErSubmitterDocviewerHandler_DocViewerSummary> => { return this.connector.rpcCall("er_submitter_docviewer", "getDocumentViewerSummary", documentKey); },
    getEditorUserName: (payload: {documentKey:string,userKey:string}): Promise<string> => { return this.connector.rpcCall("er_submitter_docviewer", "getEditorUserName", payload); },
    getIndexingFormFields: (payload: {documentKey:string,recipientDocTypeId:RecipientDocTypeRow_RecipientDocTypeId}): Promise<Array<ERRecipientIndexingField>> => { return this.connector.rpcCall("er_submitter_docviewer", "getIndexingFormFields", payload); },
    getIndexingStatus: (payload: {documentKey:string}): Promise<ErIndexingRoot> => { return this.connector.rpcCall("er_submitter_docviewer", "getIndexingStatus", payload); },
    getOcrContent: (payload: {documentKey:string}): Promise<Array<OCRBlock>> => { return this.connector.rpcCall("er_submitter_docviewer", "getOcrContent", payload); },
    getOptionsList: (payload: {documentKey:string,recipientDocTypeId:RecipientDocTypeRow_RecipientDocTypeId,optionLists:Array<string>}): Promise<Array<ERRecipientOptionList>> => { return this.connector.rpcCall("er_submitter_docviewer", "getOptionsList", payload); },
    getPackagePages: (payload: {packageKey:string}): Promise<Array<ErSubmitterDocviewerHandler_DocPages>> => { return this.connector.rpcCall("er_submitter_docviewer", "getPackagePages", payload); },
    getPageOcrContent: (payload: {pageKey:string}): Promise<OCRBlock> => { return this.connector.rpcCall("er_submitter_docviewer", "getPageOcrContent", payload); },
    getRecipientDocTypeId: (documentKey: string): Promise<RecipientDocTypeRow_RecipientDocTypeId> => { return this.connector.rpcCall("er_submitter_docviewer", "getRecipientDocTypeId", documentKey); },
    getReviewUserName: (payload: {documentKey:string,userKey:string}): Promise<string> => { return this.connector.rpcCall("er_submitter_docviewer", "getReviewUserName", payload); },
    listAllMasterDocTypes: (): Promise<Array<ErSubmitterDocviewerHandler_DocTypeFlat>> => { return this.connector.rpcCall("er_submitter_docviewer", "listAllMasterDocTypes", null); },
    listMasterDocTypes: (packageKey: string): Promise<Array<ErSubmitterDocviewerHandler_DocTypeFlat>> => { return this.connector.rpcCall("er_submitter_docviewer", "listMasterDocTypes", packageKey); },
    listRecipientDocTypes: (packageKey: string): Promise<Array<ErSubmitterDocviewerHandler_DocTypeFlat>> => { return this.connector.rpcCall("er_submitter_docviewer", "listRecipientDocTypes", packageKey); },
    listRecipientOptions: (packageKey: string): Promise<Array<ErSubmitterDocviewerHandler_RecipientOrgOption>> => { return this.connector.rpcCall("er_submitter_docviewer", "listRecipientOptions", packageKey); },
    loadDocType: (payload: {packageKey:string,masterDocTypeKey:MasterDocTypeRowExt_DocTypeKey}): Promise<ErSubmitterDocviewerHandler_DocTypeFlat> => { return this.connector.rpcCall("er_submitter_docviewer", "loadDocType", payload); },
    loadRecipientMarginSettings: (documentKey: string): Promise<Array<UIAdminRecipientMarginSettings>> => { return this.connector.rpcCall("er_submitter_docviewer", "loadRecipientMarginSettings", documentKey); },
    markReviewed: (payload: {documentKey:string,path:Array<any>}): Promise<ErIndexingData_StatusChange> => { return this.connector.rpcCall("er_submitter_docviewer", "markReviewed", payload); },
    refreshThumbnailsIfNeeded: (payload: {packageKey:string}): Promise<void> => { return this.connector.rpcCall("er_submitter_docviewer", "refreshThumbnailsIfNeeded", payload); },
    searchDocTypesForPackage: (payload: {search:string,masterDocTypeKey:MasterDocTypeRowExt_DocTypeKey,packageKey:string,limit:number}): Promise<Array<ErSubmitterDocviewerHandler_DocTypeFlat>> => { return this.connector.rpcCall("er_submitter_docviewer", "searchDocTypesForPackage", payload); },
    setMasterDocType: (payload: {documentKey:string,masterDocTypeKey:MasterDocTypeRowExt_DocTypeKey}): Promise<void> => { return this.connector.rpcCall("er_submitter_docviewer", "setMasterDocType", payload); },
    setPackageRecipient: (payload: {packageKey:string,recipientKey:string}): Promise<void> => { return this.connector.rpcCall("er_submitter_docviewer", "setPackageRecipient", payload); },
    tryIndexingFromText: (payload: {documentKey:string,fieldPath:string,subgroup:string,text:string,pageKey:string,blocks:Array<OCRBlock>}): Promise<ErIndexingRoot> => { return this.connector.rpcCall("er_submitter_docviewer", "tryIndexingFromText", payload); },
    updateDocumentPageIndex: (payload: {newDocumentKey:string,newIndex:number,oldDocumentKey:string,pageKeys:Array<string>}): Promise<void> => { return this.connector.rpcCall("er_submitter_docviewer", "updateDocumentPageIndex", payload); },
    updateIndexingStatus: (payload: {documentKey:string,indexingTree:any}): Promise<void> => { return this.connector.rpcCall("er_submitter_docviewer", "updateIndexingStatus", payload); },
    updatePageOps: (payload: {documentKey:string,pageKey:string,operations:Array<ImagenModels_PageAlteration>}): Promise<void> => { return this.connector.rpcCall("er_submitter_docviewer", "updatePageOps", payload); },
    observe: {
      getBasicDocPages: (payload: {documentKey:string}): Observable<Array<ImagenModels_AlteredPage>> => { return this.ws.rpcSubscribe("er_submitter_docviewer", "getBasicDocPages", payload); },
      getIndexingStatus: (payload: {documentKey:string}): Observable<ErIndexingRoot> => { return this.ws.rpcSubscribe("er_submitter_docviewer", "getIndexingStatus", payload); },
      getPackagePages: (payload: {packageKey:string}): Observable<Array<ErSubmitterDocviewerHandler_DocPages>> => { return this.ws.rpcSubscribe("er_submitter_docviewer", "getPackagePages", payload); },
      tryIndexingFromText: (payload: {documentKey:string,fieldPath:string,subgroup:string,text:string,pageKey:string,blocks:Array<OCRBlock>}): Observable<ErIndexingRoot> => { return this.ws.rpcSubscribe("er_submitter_docviewer", "tryIndexingFromText", payload); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace er_submitter_downloads | Class co.coolhc.hopdox.handler.ErSubmitterDownloadsHandler
  er_submitter_downloads = {
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace er_submitter_uploads | Class co.coolhc.hopdox.handler.ErSubmitterUploadsHandler
  er_submitter_uploads = {
    getSubmitterOrgs: (): Promise<Array<string>> => { return this.connector.rpcCall("er_submitter_uploads", "getSubmitterOrgs", null); },
    getUploads: (orgKey: string): Promise<Array<UISubmitterUpload>> => { return this.connector.rpcCall("er_submitter_uploads", "getUploads", orgKey); },
    observe: {
      getUploads: (orgKey: string): Observable<Array<UISubmitterUpload>> => { return this.ws.rpcSubscribe("er_submitter_uploads", "getUploads", orgKey); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace global_search | Class co.coolhc.hopdox.handler.SearchHandler
  global_search = {
    clearAllIndexes: (): Promise<void> => { return this.connector.rpcCall("global_search", "clearAllIndexes", null); },
    fullIndex: (): Promise<void> => { return this.connector.rpcCall("global_search", "fullIndex", null); },
    search: (search: UIGlobalSearchQuery): Promise<Array<UISearchResult>> => { return this.connector.rpcCall("global_search", "search", search); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace imagen_test | Class co.coolhc.hopdox.handler.AdminImagenTestHandler
  imagen_test = {
    getPackageNames: (): Promise<AdminImagenTestHandler_PackageSummaries> => { return this.connector.rpcCall("imagen_test", "getPackageNames", null); },
    getSampleImagenInterpolationLookup: (payload: {packageKey:string,xml:string}): Promise<ImagenModels_InterpolateXmlRequest> => { return this.connector.rpcCall("imagen_test", "getSampleImagenInterpolationLookup", payload); },
    testInterpolateXmlBuffer: (payload: {packageKey:string,xml:string}): Promise<void> => { return this.connector.rpcCall("imagen_test", "testInterpolateXmlBuffer", payload); },
    testInterpolateXmlNoBuffer: (payload: {packageKey:string,xml:string}): Promise<void> => { return this.connector.rpcCall("imagen_test", "testInterpolateXmlNoBuffer", payload); },
    testInterpolateXmlResult: (payload: {packageKey:string,xml:string}): Promise<string> => { return this.connector.rpcCall("imagen_test", "testInterpolateXmlResult", payload); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace orgs | Class co.coolhc.hopdox.handler.OrganizationHandler
  orgs = {
    addService: (payload: {orgKey:string,service:ServiceRowExt_Service}): Promise<void> => { return this.connector.rpcCall("orgs", "addService", payload); },
    getOrgInfo: (orgKey: string): Promise<UIOrgInfo> => { return this.connector.rpcCall("orgs", "getOrgInfo", orgKey); },
    getOrganizationUsers: (orgKey: string): Promise<Array<UIUserInfo>> => { return this.connector.rpcCall("orgs", "getOrganizationUsers", orgKey); },
    removeService: (payload: {orgKey:string,service:ServiceRowExt_Service}): Promise<void> => { return this.connector.rpcCall("orgs", "removeService", payload); },
    observe: {
      getOrgInfo: (orgKey: string): Observable<UIOrgInfo> => { return this.ws.rpcSubscribe("orgs", "getOrgInfo", orgKey); },
      getOrganizationUsers: (orgKey: string): Observable<Array<UIUserInfo>> => { return this.ws.rpcSubscribe("orgs", "getOrganizationUsers", orgKey); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace payment_accounts | Class co.coolhc.hopdox.handler.PaymentAccountsHandler
  payment_accounts = {
    addPaymentAccount: (newAccount: UIEditablePaymentAccount): Promise<void> => { return this.connector.rpcCall("payment_accounts", "addPaymentAccount", newAccount); },
    editPaymentAccount: (payload: {accountKey:string,label:string,allowsDebit:boolean}): Promise<void> => { return this.connector.rpcCall("payment_accounts", "editPaymentAccount", payload); },
    loadDefaultPaymentAccounts: (orgKeys: Array<string>): Promise<Array<UIEditDefaultPaymentAccount>> => { return this.connector.rpcCall("payment_accounts", "loadDefaultPaymentAccounts", orgKeys); },
    loadPaymentAccounts: (orgKeys: Array<string>): Promise<UIBilling> => { return this.connector.rpcCall("payment_accounts", "loadPaymentAccounts", orgKeys); },
    toggleAccountActive: (accountKey: string): Promise<Array<UIEditDefaultPaymentAccount>> => { return this.connector.rpcCall("payment_accounts", "toggleAccountActive", accountKey); },
    updateDefaultPaymentAccount: (defaultPaymentAccount: UIEditDefaultPaymentAccount): Promise<void> => { return this.connector.rpcCall("payment_accounts", "updateDefaultPaymentAccount", defaultPaymentAccount); },
    updateHopdoxFeeForSubmitterOrg: (payload: {orgKey:string,newHopdoxFee:number}): Promise<void> => { return this.connector.rpcCall("payment_accounts", "updateHopdoxFeeForSubmitterOrg", payload); },
    observe: {
      loadPaymentAccounts: (orgKeys: Array<string>): Observable<UIBilling> => { return this.ws.rpcSubscribe("payment_accounts", "loadPaymentAccounts", orgKeys); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace relay | Class co.coolhc.hopdox.handler.RelayHandler
  relay = {
    relay: (request: RelayHandler_RelayRequest): Promise<RelayHandler_RelayResponse> => { return this.connector.rpcCall("relay", "relay", request); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace tasks | Class co.coolhc.hopdox.handler.TasksHandler
  tasks = {
    cronTasksList: (): Promise<Array<UITasks_CronRow>> => { return this.connector.rpcCall("tasks", "cronTasksList", null); },
    getTaskData: (payload: {taskReadyId:TaskReadyRow_TaskReadyId}): Promise<UITasks_QueueDetails> => { return this.connector.rpcCall("tasks", "getTaskData", payload); },
    queueDetails: (payload: {taskQueueName:string}): Promise<UITasks_QueueDetails> => { return this.connector.rpcCall("tasks", "queueDetails", payload); },
    queueSummaryList: (): Promise<Array<UITasks_QueueSummary>> => { return this.connector.rpcCall("tasks", "queueSummaryList", null); },
    readyTasksList: (): Promise<Array<UITasks_TaskRow>> => { return this.connector.rpcCall("tasks", "readyTasksList", null); },
    runTaskRunning: (payload: {taskTypeId:TaskTypeRow_TaskTypeId,taskServerId:TaskServerRow_TaskServerId,referenceId:number}): Promise<TaskReadyRow_TaskReadyId> => { return this.connector.rpcCall("tasks", "runTaskRunning", payload); },
    serverSummaryList: (): Promise<Array<UITasks_ServerSummary>> => { return this.connector.rpcCall("tasks", "serverSummaryList", null); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace testIndexing | Class co.coolhc.hopdox.handler.TestIndexingHandler
  testIndexing = {
    getOptionsList: (payload: {recipientKey:string,recipientDocTypeId:number,optionLists:Array<string>}): Promise<Array<ERRecipientOptionList>> => { return this.connector.rpcCall("testIndexing", "getOptionsList", payload); },
    getSampleDocTypeId: (payload: {recipientKey:string}): Promise<number> => { return this.connector.rpcCall("testIndexing", "getSampleDocTypeId", payload); },
    getSampleFields: (payload: {recipientKey:string,recipientDocTypeId:number}): Promise<Array<ERRecipientIndexingField>> => { return this.connector.rpcCall("testIndexing", "getSampleFields", payload); },
    getSampleRecipient: (): Promise<string> => { return this.connector.rpcCall("testIndexing", "getSampleRecipient", null); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace toggles | Class co.coolhc.hopdox.handler.TogglesHandler
  toggles = {
    loadToggles: (): Promise<Toggles> => { return this.connector.rpcCall("toggles", "loadToggles", null); },
    loadTogglesList: (): Promise<UITogglesList> => { return this.connector.rpcCall("toggles", "loadTogglesList", null); },
    saveTogglesList: (togglesList: UITogglesList): Promise<void> => { return this.connector.rpcCall("toggles", "saveTogglesList", togglesList); },
    observe: {
      loadToggles: (): Observable<Toggles> => { return this.ws.rpcSubscribe("toggles", "loadToggles", null); },
      loadTogglesList: (): Observable<UITogglesList> => { return this.ws.rpcSubscribe("toggles", "loadTogglesList", null); }
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace unsubscribe | Class co.coolhc.hopdox.handler.UnsubscribeHandler
  unsubscribe = {
    getNotificationSettings: (settingsToken: string): Promise<UISettingsGroups> => { return this.connector.rpcCall("unsubscribe", "getNotificationSettings", settingsToken); },
    saveNotificationSettings: (payload: {settingsToken:string,settings:UISettingsGroups}): Promise<UISettingsGroups> => { return this.connector.rpcCall("unsubscribe", "saveNotificationSettings", payload); },
    observe: {
    },
    with_updates: {
    }
  }
  // ---

  // ---> Namespace users | Class co.coolhc.hopdox.handler.UserHandler
  users = {
    acceptInvite: (inviteToken: string): Promise<UIUserAcceptInvite> => { return this.connector.rpcCall("users", "acceptInvite", inviteToken); },
    beginDeleteAccount: (): Promise<void> => { return this.connector.rpcCall("users", "beginDeleteAccount", null); },
    beginResetPassword: (payload: {email:string}): Promise<void> => { return this.connector.rpcCall("users", "beginResetPassword", payload); },
    changePassword: (payload: {newPassword:string,confirmPassword:string}): Promise<void> => { return this.connector.rpcCall("users", "changePassword", payload); },
    getInvite: (inviteToken: string): Promise<UIUserAcceptInvite> => { return this.connector.rpcCall("users", "getInvite", inviteToken); },
    getInvitedUsers: (): Promise<Array<UIUserInvite>> => { return this.connector.rpcCall("users", "getInvitedUsers", null); },
    getSelfInfo: (): Promise<UIUserInfo> => { return this.connector.rpcCall("users", "getSelfInfo", null); },
    getSelfProfile: (): Promise<UIEditableProfile> => { return this.connector.rpcCall("users", "getSelfProfile", null); },
    loadUserNotificationSettings: (payload: {userKey:string}): Promise<Array<UIAdminUserOrgNotificationSettings>> => { return this.connector.rpcCall("users", "loadUserNotificationSettings", payload); },
    login: (payload: {username:string,password:string}): Promise<void> => { return this.connector.rpcCall("users", "login", payload); },
    logout: (): Promise<void> => { return this.connector.rpcCall("users", "logout", null); },
    masqueradeAsUser: (userKey: string): Promise<void> => { return this.connector.rpcCall("users", "masqueradeAsUser", userKey); },
    organizations: (): Promise<Array<string>> => { return this.connector.rpcCall("users", "organizations", null); },
    permissions: (permissionContext: UIPermissionContext): Promise<Array<Permission>> => { return this.connector.rpcCall("users", "permissions", permissionContext); },
    registerNewUser: (registerUser: UIRegisterUser): Promise<void> => { return this.connector.rpcCall("users", "registerNewUser", registerUser); },
    registerNewUserWithProject: (registerUser: UIRegisterUserWithProject): Promise<void> => { return this.connector.rpcCall("users", "registerNewUserWithProject", registerUser); },
    resumeSession: (): Promise<void> => { return this.connector.rpcCall("users", "resumeSession", null); },
    saveProfile: (profile: UIEditableProfile): Promise<UIEditableProfile> => { return this.connector.rpcCall("users", "saveProfile", profile); },
    sendVerifyEmail: (payload: {email:string}): Promise<void> => { return this.connector.rpcCall("users", "sendVerifyEmail", payload); },
    sendVerifyMobile: (payload: {mobileNumber:string}): Promise<void> => { return this.connector.rpcCall("users", "sendVerifyMobile", payload); },
    services: (): Promise<Array<ServiceRowExt_Service>> => { return this.connector.rpcCall("users", "services", null); },
    updateUserNotificationSettings: (updatedSettings: UIAdminUserOrgNotificationSettings): Promise<void> => { return this.connector.rpcCall("users", "updateUserNotificationSettings", updatedSettings); },
    verifyAndChangeEmail: (payload: {oldEmail:string,newEmail:string,token:string}): Promise<void> => { return this.connector.rpcCall("users", "verifyAndChangeEmail", payload); },
    verifyDeleteAccount: (payload: {code:string}): Promise<void> => { return this.connector.rpcCall("users", "verifyDeleteAccount", payload); },
    verifyEmail: (payload: {email:string,token:string}): Promise<void> => { return this.connector.rpcCall("users", "verifyEmail", payload); },
    verifyMobile: (payload: {mobileNumber:string,code:string}): Promise<void> => { return this.connector.rpcCall("users", "verifyMobile", payload); },
    verifyResetPassword: (payload: {email:string,code:string,password:string}): Promise<void> => { return this.connector.rpcCall("users", "verifyResetPassword", payload); },
    observe: {
      getInvitedUsers: (): Observable<Array<UIUserInvite>> => { return this.ws.rpcSubscribe("users", "getInvitedUsers", null); },
      getSelfInfo: (): Observable<UIUserInfo> => { return this.ws.rpcSubscribe("users", "getSelfInfo", null); },
      getSelfProfile: (): Observable<UIEditableProfile> => { return this.ws.rpcSubscribe("users", "getSelfProfile", null); }
    },
    with_updates: {
    }
  }
  // ---

}
