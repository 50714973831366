<template>
  <HopToast />
  <ConfirmDialog></ConfirmDialog>
  <BlockUI :blocked="waiting" :fullscreen="true">
    <ProgressSpinner v-if="waiting" style="position: fixed;top: 50%;left:50%;transform: translate(-50%, -50%);" />
  </BlockUI>
  <div class="md:h-screen md:flex bg-gray-100">
<!--    <ERecordMenu/>-->
    <div class="flex flex-row grow w-full md:h-full md:max-h-full">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import {ref} from "vue";
import {useDestroyable} from "~/core/util/composition-util";
import {useCore} from "~/core/core.service";
import ERecordMenu from "~/components/ERecordMenu.vue";
import HopToast from "~/components/HopToast.vue";
const { autoUnsubscribe } = useDestroyable();
const toast = useToast();
const core = useCore();
core.toast.setToast(toast);

const waiting = ref(false);
autoUnsubscribe(core.wait.observeWaiting().subscribe(currentWaiting => {
  waiting.value = currentWaiting;
}));
</script>
