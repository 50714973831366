import {ServiceRowExt_Service} from "~/core/rpc/rpc-models";
import {SessionService} from "~/core/session.service";
import {parsePermissions, PermissionService} from "~/core/permission.service";
import {TogglesService} from "~/core/toggles.service";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {deepEquals} from "~/core/util/object-util";
import {MenuItem} from "~/vendor/MenuItem";
import {DefaultMenu} from "~/core/menus";
import {NavigationFailure, NavigationHookAfter, RouteLocationNormalized, RouteLocationNormalizedLoaded} from "vue-router";
import router from "~/router";
import {BreadcrumbService} from "~/core/breadcrumb.service";
import {RPCService} from "~/core/rpc.service";

export interface HopdoxMenuItem {
  breadcrumbPrevious?: boolean;

  title: string;
  subTitle?: string;
  titleShort?: string;
  icon?: string;
  link?: string;
  url?: string;
  badge?: string;
  badgeColor?: string;
  children?: Array<HopdoxMenuItem>;
  expanded?: boolean;
  bottom?: boolean;
  button?: boolean;

  include?: boolean; // boolean toggle on whether to actually include this menu item (default/null is true)
  toggle?: string;
  permissions?: string;
  service?: ServiceRowExt_Service;
}

export type PrimeMenuItem = MenuItem & {
  subTitle? : string;
};

export type TitleProducer = {key: string, observable: Observable<string>};
export type TitleGenerator = (route: RouteLocationNormalized, rpc: RPCService) => TitleProducer;
export type MenuProducer = {key: string, observable: Observable<Array<HopdoxMenuItem>>};
export type MenuGenerator = (route: RouteLocationNormalizedLoaded, rpc: RPCService) => MenuProducer;
export type ParentLinkGenerator = (route: RouteLocationNormalizedLoaded) => string;

export class MenuService {
  private primeMenu: BehaviorSubject<Array<MenuItem>> = new BehaviorSubject<Array<MenuItem>>([]);
  private menu: BehaviorSubject<Array<HopdoxMenuItem>> = new BehaviorSubject<Array<HopdoxMenuItem>>([]);
  private bottomMenu: BehaviorSubject<Array<HopdoxMenuItem>> = new BehaviorSubject<Array<HopdoxMenuItem>>([]);
  private badges: Map<string, { badge: string, badgeColor: string}> = new Map();
  private badgesObservable: BehaviorSubject<Map<string, { badge: string, badgeColor: string}>> = new BehaviorSubject(this.badges);
  private refreshCount: number = 0;

  private activeMenuKey: string;
  private activeMenuSubscription: Subscription;
  private activeMenu: HopdoxMenuItem[];

  constructor(
    private readonly session: SessionService,
    private readonly rpc: RPCService,
    private readonly permissions: PermissionService,
    private readonly toggles: TogglesService,
    private readonly crumbs: BreadcrumbService,
  ) {
    router.afterEach(this.createMenuTracker());
    session.observeUserInfo().subscribe((user) => {
      this.refreshMenuItems();
    });
  }

  observeBadges() {
    return this.badgesObservable;
  }

  observePrimeMenuOptions(): BehaviorSubject<Array<MenuItem>> {
    return this.primeMenu;
  }

  observeMenuOptions(): BehaviorSubject<Array<HopdoxMenuItem>> {
    return this.menu;
  }

  observeBottomMenuOptions(): BehaviorSubject<Array<HopdoxMenuItem>> {
    return this.bottomMenu;
  }

  updateBadge(link: string, badge: string, badgeColor: string) {
    if (!badge) {
      this.badges.delete(link);
    } else {
      this.badges.set(link, { badge, badgeColor });
    }
    this.badgesObservable.next(this.badges);
  }

  private setMenuItems(menuItems: HopdoxMenuItem[]) {
    if (!menuItems) menuItems = [];
    this.activeMenu = menuItems;
    this.refreshMenuItems();
  }

  private refreshMenuItems() {
    this.resolveMenuItems(this.activeMenu).then((items) => {
      this.emitUpdatedMenuItems(items);
    }, (error) => {

    });
  }

  private emitUpdatedMenuItems(items: Array<HopdoxMenuItem>) {
    if (!deepEquals(this.menu.value, items)) {
      this.menu.next(items);
      this.primeMenu.next(this.primeItems(items));
      this.bottomMenu.next(this.filterBottomItems(items));
    }
  }

  private primeItems(items: HopdoxMenuItem[]): Array<MenuItem> {
    const newItems: MenuItem[] = [];
    items.forEach((item, index) => {
      newItems.push(this.primeItem(index.toString(), item));
    });
    return newItems;
  }

  private primeItem(key: string, item: HopdoxMenuItem): PrimeMenuItem {
    const i: MenuItem = {
    };
    if (item.breadcrumbPrevious) {
      const previousCrumb = this.crumbs.previousBreadcrumb();
      if (previousCrumb) {
        i.key = key;
        i.label = previousCrumb.label;
        i.icon = "pi pi-arrow-left";
        i.to = previousCrumb.to;
      }
    } else {
      i.key = key;
      i.label = item.title;
      i.icon = "pi " + item.icon;
      i.to = item.link;
      i.expanded = item.expanded;
      i.subTitle = item.subTitle;
      i.command = event => {
        console.log(event);
      };
      if (item.children && item.children.length) {
        i.items = [];
        item.children.forEach((subItem, index) => {
          i.items.push(this.primeItem(key + "_" + String(index), subItem));
        });
      }
    }
    return i;
  }

  private filterBottomItems(items: HopdoxMenuItem[]): Array<HopdoxMenuItem> {
    const out: Array<HopdoxMenuItem> = [];
    if (items) {
      items.forEach(item => {
        if (item.bottom) out.push(item);
        if (item.children) {
          this.filterBottomItems(item.children).forEach((child) => {
            out.push(item);
          });
        }
      });
    }
    return out;
  }

  private resolveMenuItems(items: HopdoxMenuItem[]): Promise<HopdoxMenuItem[]> {
    this.refreshCount++;
    const startCount = this.refreshCount;
    const stack: Array<HopdoxMenuItem[]> = [];
    if (items) stack.push([...items]);

    const result: Array<HopdoxMenuItem> = [];
    const resultStack: Array<HopdoxMenuItem[]> = [];
    resultStack.push(result);

    const next = (resolve: any, reject: any) => {
      if (startCount !== this.refreshCount) reject();
      // loop until we're done--but might return in the middle to do an asynchronous process before continuing
      while (stack.length > 0) {
        // peek at the list of items at the end of the stack
        const itemList: HopdoxMenuItem[] = stack[stack.length - 1];
        // console.log(JSON.stringify(itemList));
        if (itemList.length === 0) {
          // finished the menu last item of the current sub-list, so pop it off
          stack.pop();
          // clean up result sub-list and possibly parent if the result doesn't have anything to include
          const resultList = resultStack.pop();
          if (resultList && resultList.length > 0) {
            if (resultStack.length > 0) {
              // attach children to the parents
              const parentList = resultStack[resultStack.length - 1];
              parentList[parentList.length - 1].children = resultList;
            }
          } else {
            if (resultStack.length > 0) {
              // pop off the entire parent node if it doesn't have any children after the processing
              resultStack[resultStack.length - 1].pop();
            }
          }
          continue;
        } else {
          const menuItem = itemList.shift();
          const badgeData = this.badges.get(menuItem.link);
          if (badgeData) {
            menuItem.badge = badgeData.badge;
            menuItem.badgeColor = badgeData.badgeColor;
          }
          const attach = (item: HopdoxMenuItem) => {
            destList.push(item);
            if (item.children) {
              // if there are children to process, push them onto the stack
              stack.push(item.children);
              resultStack.push([]);
            }
          };


          const asyncAttach = () => {
            const promises: Array<Promise<boolean>> = [];
            if (menuItem.permissions) {
              // at this point we break into an asynchronous process...
              promises.push(this.permissions.checkPermissions(
                parsePermissions(menuItem.permissions)
              ));
            } else {
              promises.push(Promise.resolve(true));
            }

            if (menuItem.service) {
              // at this point we break into an asynchronous process...
              promises.push(this.permissions.checkService(menuItem.service));
            } else {
              promises.push(Promise.resolve(true));
            }

            Promise.all(promises)
              .then(results => {
                // Check if all promises resolved to true
                const allTrue = results.every(result => result === true);

                if (allTrue) {
                  // Run finalizing code
                  attach(menuItem);
                }
                next(resolve, reject);
              })
              .catch(error => {
                next(resolve, reject);
              });
          };

          const destList = resultStack[resultStack.length - 1];

          // process the next source item
          if (menuItem.include === false) {
            // just have to skip this item since it not supposed to be included
          } else {
            // check to see if the menu should be included based on the toggles
            if (menuItem.toggle) {
              this.toggles.getToggles().then(toggles => {
                if (toggles && !!(toggles as any)[menuItem.toggle]) {
                  asyncAttach();
                  return;
                } else {
                  next(resolve, reject);
                }
              });
              // return here because this function will be continued asynchronously
              return;
            } else {
              asyncAttach();
              return;
            }
          }
        }
      }
      // finally finished the processing
      resolve(result);
    };

    return new Promise((resolve, reject) => {
      next(resolve, reject);
    });
  }

  private createMenuTracker(): NavigationHookAfter {
    return (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void) => {
      const meta = to.meta;
      let menuGenerator = typeof meta.menu === 'function' ? (meta.menu as MenuGenerator) : DefaultMenu;
      let menuProduct = menuGenerator(to, this.rpc);
      if (this.activeMenuKey !== menuProduct.key) {
        if (this.activeMenuSubscription) this.activeMenuSubscription.unsubscribe();
        this.activeMenuKey = menuProduct.key;
        this.activeMenuSubscription = menuProduct.observable.subscribe((defaultItems) => {
          this.setMenuItems(defaultItems);
        });
      }
    };
  }
}
