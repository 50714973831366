import {inject, InjectionKey} from "vue";
import {RPCService} from "./rpc.service";
import {NavigationService} from "~/core/navigation.service";
import {PermissionService} from "~/core/permission.service";
import {SessionService} from "~/core/session.service";
import {TogglesService} from "~/core/toggles.service";
import {ThrottleService} from "~/core/throttle.service";
import {ToastService} from "~/core/toast.service";
import {MenuService} from "~/core/menu.service";
import {BreadcrumbService} from "~/core/breadcrumb.service";
import {WaitService} from "~/core/wait.service";

export function injectGlobal<T>(key: InjectionKey<T> | string): T {
  let value = inject(key);
  if (value === undefined) {
    throw new Error("You requested " + JSON.stringify(key) + " but the global was undefined; please register it in main.ts");
  }
  return value as T;
}

export class CoreService {
  static KEY= "CORE";

  readonly wait: WaitService;
  readonly throttle: ThrottleService;
  readonly toast: ToastService;
  readonly rpc: RPCService;
  readonly permissions: PermissionService;
  readonly session: SessionService;
  readonly toggles: TogglesService;
  readonly crumbs: BreadcrumbService;
  readonly nav: NavigationService;
  readonly menu: MenuService;

  constructor(
  ) {
    this.wait = new WaitService();
    this.throttle = new ThrottleService();
    this.toast = new ToastService();
    this.rpc = new RPCService(document, this.toast);
    this.permissions = new PermissionService(this.rpc);
    this.session = new SessionService(this.permissions, this.rpc);
    this.toggles = new TogglesService(this.session, this.rpc);
    this.crumbs = new BreadcrumbService(this.rpc);
    this.nav = new NavigationService(this.permissions, this.toast, this.toggles, this.session);
    this.menu = new MenuService(this.session, this.rpc, this.permissions, this.toggles, this.crumbs);

    this.rpc.setUseVerboseRpcPaths(true);
  }
}

export function useCore(): CoreService {
  return injectGlobal(CoreService.KEY);
}
