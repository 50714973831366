import {RPCHandlers} from "./rpc/rpc-handlers";
import {RPCConnector} from "./rpc/rpc-connector";
import {ToastService} from "./toast.service";
import {merge, Observable} from "rxjs";
import {RPCError} from "./rpc/rpc-response";
import {WebSocketConnector} from "./websocket/web-socket-connector";

export class RPCService extends RPCHandlers {
    private _awaitingUpdate: boolean = false;

    constructor(
        document: Document,
        private readonly toast: ToastService,
    ) {
        super(new RPCConnector(document, toast), new WebSocketConnector(document, toast));
        this.observeForceUpdate().subscribe(() => {
          this._awaitingUpdate = true;
        });
    }

    setUseVerboseRpcPaths(value: boolean) {
        this.connector.setUseVerboseRpcPaths(value);
    }

    awaitingUpdate(): boolean {
        return this._awaitingUpdate;
    }

    observeNextAppVersion(): Observable<string> {
        return merge(this.connector.observeNextAppVersion(), this.ws.observeNextAppVersion());
    }

    observeForceUpdate(): Observable<void> {
        return this.connector.observeForceUpdate();
    }

    observeErrors(): Observable<RPCError> {
        return this.connector.observeErrors();
    }

    invokeMissingEndpoint(namespace?:string, method?:string): Promise<void> { return this.connector.rpcCall(namespace ? namespace : "missing", method ? method : "missing", null); }
}
