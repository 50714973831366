import {NavigationGuardWithThis, RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw} from "vue-router";
import {PermissionService} from "~/core/permission.service";
import {NavigationService} from "~/core/navigation.service";
import {SessionService} from "~/core/session.service";
import {Permission, ServiceRowExt_Service} from "~/core/rpc/rpc-models";


export interface ServiceRedirect {
  replacers: [source: RegExp|string, replacement: string][],
  service: ServiceRowExt_Service;
}

function loadLastMetaValueFromMatched(to: RouteLocationNormalized, key: string): any {
  let value = null;
  to.matched.forEach(m => {
    if (m.meta[key] !== null && typeof m.meta[key] !== 'undefined') value = m.meta[key];
  });
  return value;
}

/** Only allow navigation to destination if user is authenticated */
export function createAuthenticatedGuard(nav: NavigationService, session: SessionService): NavigationGuardWithThis<any> {
  return (to, from) => {
    const checkAuthenticated = !!loadLastMetaValueFromMatched(to, "authenticated");
    if (!checkAuthenticated) {
      // no permissions to check, to pass the guard
      return Promise.resolve(true);
    } else {
      return new Promise(resolve => {
        session.isAuthenticatedUnsafe().then((authenticated) => {
          if (authenticated) {
            resolve(true);
          } else {
            nav.paths.home().then((path) => {
              resolve(path);
            });
          }
        }, (error) => {
          if ("maintenance" in error) {
            error.displayed = true;
            resolve(nav.maintenance());
          } else {
            session.refreshSession(true).then(() => {
              nav.paths.home().then((path) => {
                resolve(path);
              });
            });
          }
          resolve(false);
        });
      });
    }
  };
}

/** Only allow navigation to destination if user is not authenticated */
export function createNotAuthenticatedGuard(nav: NavigationService, session: SessionService): NavigationGuardWithThis<any> {
  return (to, from) => {
    const checkUnauthenticated = !!loadLastMetaValueFromMatched(to, "unauthenticated");
    if (!checkUnauthenticated) {
      // no permissions to check, to pass the guard
      return Promise.resolve(true);
    } else {
      return new Promise(resolve => {
        session.isAuthenticatedUnsafe().then((authenticated) => {
          if (!authenticated) {
            resolve(true);
          } else {
            nav.paths.home().then((path) => {
              resolve(path);
            });
          }
        }, (error) => {
          if ("maintenance" in error) {
            resolve(nav.maintenance());
          } else {
            session.refreshSession(true).then(() => {
              nav.paths.home().then((path) => {
                resolve(path);
              });
            });
          }
          resolve(false);
        });
      });
    }
  };
}

export function createPermissionGuard(nav: NavigationService, permissions: PermissionService): NavigationGuardWithThis<any> {
  return async (to, from) => {
    const securityContext = loadLastMetaValueFromMatched(to, "permissionContext");
    const service = loadLastMetaValueFromMatched(to, "service");
    let routePermissions = loadLastMetaValueFromMatched(to, "permissions");
    let serviceRedirects = loadLastMetaValueFromMatched(to, "serviceRedirects") as ServiceRedirect[] || []

    let result = await createNavigationPromise(nav, permissions, securityContext, service, routePermissions)

    if (result == "Failed"){
      if (serviceRedirects.length > 0){
        for (let i = 0; i < serviceRedirects.length; i++){
          result = await createNavigationPromise(nav, permissions, undefined, serviceRedirects[i].service, undefined);
          if (result == "Maintenance") break;
          if (result == "Failed") continue;
          if (result == "Success") return serviceRedirects[i].replacers.reduce((a, rep) => a.replace(rep[0], rep[1]), to.fullPath)
        }
      }
    }
    switch (result){
      case "Success":
        return true;
      case "Maintenance":
        return nav.paths.maintenance();
      case "Failed":
        return nav.paths.home();
      default:
        return false;
    }
  }
}

type AuthResult = "Success" | "Maintenance" | "Failed"

function createNavigationPromise(nav: NavigationService, permissions: PermissionService, securityContext: any, service: any,  routePermissions: any): Promise<AuthResult>{
  if (securityContext && !routePermissions) routePermissions = "";
  if (!routePermissions && !securityContext && !service) {
    // no permissions to check, to pass the guard
    return Promise.resolve("Success");
  } else {
    return new Promise(resolve => {
      const errorHandler = (error: any) => {
        if (error && "maintenance" in error) {
          resolve("Maintenance");
        } else {
          resolve("Failed")
        }
      };
      const responseHandler = (permitted: boolean = null, permissions: Permission[] = null) => {
        if (permitted != null && permissions != null && permitted && permissions.length) {
          resolve("Success");
        } else if (permitted == null && permissions != null && permissions.length) {
          resolve("Success");
        } else if (permitted != null && permissions == null && permitted) {
          resolve("Success");
        } else {
          resolve("Failed")
        }
      };

      if (!service) {
        if (!routePermissions && securityContext) {
          permissions.getContextPermissions(securityContext).then((result) => responseHandler(null, result), errorHandler);
        } else if (routePermissions && !securityContext) {
          permissions.evalPermissionsUnsafe(routePermissions).then((permitted) => responseHandler(permitted, null), errorHandler);
        } else {
          permissions.getContextPermissions(securityContext).then((result) => {
            permissions.evalPermissionsUnsafe(routePermissions).then((permitted) => responseHandler(permitted, result), errorHandler);
          }, errorHandler);
        }
      } else {
        permissions.getServices().then(services => {
          if (services.includes(service)) {
            if (!routePermissions && securityContext) {
              permissions.getContextPermissions(securityContext).then((result) => responseHandler(null, result), errorHandler);
            } else if (routePermissions && !securityContext) {
              permissions.evalPermissionsUnsafe(routePermissions).then((permitted) => responseHandler(permitted, null), errorHandler);
            } else if (!routePermissions && !securityContext) {
              responseHandler(true, null);
            } else {
              permissions.getContextPermissions(securityContext).then((result) => {
                permissions.evalPermissionsUnsafe(routePermissions).then((permitted) => responseHandler(permitted, result), errorHandler);
              }, errorHandler);
            }
          } else {
            errorHandler(null);
          }
        }, errorHandler);
      }
    });
  }
}
