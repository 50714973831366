import {createApp} from 'vue'
import './style.css'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import "primeicons/primeicons.css";
import router from './router';
import ToastService from 'primevue/toastservice';
import {CoreService} from "./core/core.service";
import Tooltip from 'primevue/tooltip';
import 'highlight.js/styles/stackoverflow-dark.css'
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import js from 'highlight.js/lib/languages/javascript';
import hljsVuePlugin from "@highlightjs/vue-plugin";
import ConfirmationService from 'primevue/confirmationservice';
import PrimeVuePlugin from './primevue';
import BadgeDirective from 'primevue/badgedirective';
import HopIf from "~/components/HopIf.vue";
import Lara from '@primevue/themes/lara';
import TogglesIf from "~/components/TogglesIf.vue";
import {definePreset} from "@primevue/themes";
import {APP_VERSION} from "~/app.version";

hljs.registerLanguage('json', json);
hljs.registerLanguage('javascript', js);

const app = createApp(App);

const HopdoxThemePreset = definePreset(Lara, {
  semantic: {
    primary: {
      50: 'var(--app-primary-50)',
      100: 'var(--app-primary-100)',
      200: 'var(--app-primary-200)',
      300: 'var(--app-primary-300)',
      400: 'var(--app-primary-400)',
      500: 'var(--app-primary-500)',
      600: 'var(--app-primary-600)',
      700: 'var(--app-primary-700)',
      800: 'var(--app-primary-800)',
      900: 'var(--app-primary-900)',
      950: 'var(--app-primary-950)',
    }
  }
});

//PrimeVue
app.use(PrimeVue, {
  theme: {
    preset: HopdoxThemePreset,
    options: {
      darkModeSelector: '.theme-dark',
    }
  }
});
app.use(PrimeVuePlugin); //TODO: Do we actually need this now that we have @primevue/auto-import-resolver? (https://primevue.org/autoimport/)
app.use(ToastService);
app.use(router);
app.component('hop-if', HopIf);
app.component('toggles-if', TogglesIf);
app.directive('tooltip', Tooltip);
app.use(hljsVuePlugin);
app.use(ConfirmationService);
app.directive('badge', BadgeDirective);

app.directive('invisible', {
  updated(el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
    el.style.height = binding.value ? 'auto' : `${el.offsetHeight}px`;
    el.style.overflow = binding.value ? 'visible' : 'hidden';
  },
});

// Inject global core service
// CoreService -> NavigationService sets up app router
// CoreService -> ToastService sets up toast
const core = new CoreService();
app.provide(CoreService.KEY, core);

app.config.errorHandler = (err, vm, info) => {
  // Handle the error here (e.g., log to a server, display a user-friendly message)
  console.error(err, vm, info);
};

console.log('APP VERSION: ', APP_VERSION);

app.mount('#app');
