
export type RPCTargetMode = "default"|"test";

let targetMode: RPCTargetMode = "default";
function getProtocolTargetHost(document: Document, ws: boolean): string {
  let protocol = document.location.protocol;
  let hostname = document.location.hostname;
  let locationPort = document.location.port;
  if (targetMode === "test") {
    protocol = "https:";
    hostname = "test.hopdox.com" as any;
    locationPort = "443";
  }
  let port = "";
  if (hostname === "localhost" || hostname === "127.0.0.1" || hostname.startsWith("172.16") || hostname.startsWith("192.168") || hostname.startsWith("10.") || locationPort.includes("8100")) {
    port = ":8081";
  }

  if (ws) {
    return (protocol === 'https:' ? 'wss:' : 'ws:') +'//'+ hostname + port;
  } else {
    return protocol + '//' + hostname + port;
  }
}

export function getRPCTargetHost(document: Document): string {
  return getProtocolTargetHost(document, false);
}

export function getWSTargetHost(document: Document): string {
  return getProtocolTargetHost(document, true);
}
