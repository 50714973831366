// primevue.ts
import {App} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Textarea from "primevue/textarea";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel";
import Drawer from "primevue/drawer";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Chip from "primevue/chip";
import MultiSelect from "primevue/multiselect";
import Dialog from "primevue/dialog";
import Card from "primevue/card";
import Listbox from "primevue/listbox";
import Checkbox from 'primevue/checkbox';
import ConfirmDialog from 'primevue/confirmdialog';
import OverlayPanel from 'primevue/overlaypanel';
import Toast from 'primevue/toast';
import InputNumber from 'primevue/inputnumber';
import Divider from 'primevue/divider';
import Menu from 'primevue/menu';
import DatePicker from 'primevue/datepicker';
import InputMask from 'primevue/inputmask';
import RadioButton from 'primevue/radiobutton';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ProgressSpinner from 'primevue/progressspinner';
import FileUpload from 'primevue/fileupload';
import SplitButton from 'primevue/splitbutton';
import Password from 'primevue/password';
import Knob from 'primevue/knob';
import PanelMenu from 'primevue/panelmenu';
import SelectButton from 'primevue/selectbutton';
import Badge from 'primevue/badge';
import OverlayBadge from 'primevue/overlaybadge';
import Menubar from 'primevue/menubar';
import ConfirmPopup from 'primevue/confirmpopup';
import AutoComplete from 'primevue/autocomplete';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Breadcrumb from 'primevue/breadcrumb';
import Fieldset from "primevue/fieldset";
import BlockUI from "primevue/blockui";
import Timeline from "primevue/timeline";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import ButtonGroup from 'primevue/buttongroup';
import CascadeSelect from 'primevue/cascadeselect';
import FloatLabel from 'primevue/floatlabel';
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Select from "primevue/select";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import Popover from "primevue/popover";
import AccordionPanel from "primevue/accordionpanel";
import AccordionContent from "primevue/accordioncontent";
import AccordionHeader from "primevue/accordionheader";

export default {
  install: (app: App) => {
    app.component('Button', Button);
    app.component('InputText', InputText);
    app.component('Textarea', Textarea);
    app.component('Toolbar', Toolbar);
    app.component('TabView', TabView);
    app.component('TabPanel', TabPanel);
    app.component('Dropdown', Dropdown);
    app.component('Drawer', Drawer);
    app.component('Select', Select);
    app.component('DataTable', DataTable);
    app.component('Column', Column);
    app.component('ColumnGroup', ColumnGroup);
    app.component('Row', Row);
    app.component('Chip', Chip);
    app.component('MultiSelect', MultiSelect);
    app.component('Dialog', Dialog);
    app.component('Card', Card);
    app.component('Listbox', Listbox);
    app.component('Checkbox', Checkbox);
    app.component('ConfirmDialog', ConfirmDialog);
    app.component('OverlayPanel', OverlayPanel);
    app.component('Toast', Toast);
    app.component('InputNumber', InputNumber);
    app.component('Divider', Divider);
    app.component('Menu', Menu);
    app.component('DatePicker', DatePicker);
    app.component('InputMask', InputMask);
    app.component('RadioButton', RadioButton);
    app.component('Accordion', Accordion);
    app.component('AccordionTab', AccordionTab);
    app.component('AccordionHeader', AccordionHeader);
    app.component('AccordionPanel', AccordionPanel);
    app.component('AccordionContent', AccordionContent);
    app.component('ProgressSpinner', ProgressSpinner);
    app.component('FileUpload', FileUpload);
    app.component('SplitButton', SplitButton);
    app.component('Password', Password);
    app.component('Knob', Knob);
    app.component('PanelMenu', PanelMenu);
    app.component('SelectButton', SelectButton);
    app.component('Badge', Badge);
    app.component('Menubar', Menubar);
    app.component('ConfirmPopup', ConfirmPopup);
    app.component('AutoComplete', AutoComplete);
    app.component('Splitter', Splitter);
    app.component('SplitterPanel', SplitterPanel);
    app.component('Breadcrumb', Breadcrumb);
    app.component('Fieldset', Fieldset);
    app.component('BlockUI', BlockUI);
    app.component('Timeline', Timeline);
    app.component('IconField', IconField);
    app.component('InputIcon', InputIcon);
    app.component('ButtonGroup', ButtonGroup);
    app.component('CascadeSelect', CascadeSelect);
    app.component('FloatLabel', FloatLabel);
    app.component('Tabs', Tabs);
    app.component('TabList', TabList);
    app.component('Tab', Tab);
    app.component('Popover', Popover);
    app.component('TabPanels', TabPanels);
    app.component('OverlayBadge', OverlayBadge);
  },
};
