<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script setup lang="ts">
import {computed, ref, Ref, watch} from "vue";
import {NavigationFailure, RouteLocationNormalized, useRouter} from "vue-router";

import DashboardLayout from "./components/PrimaryLayout.vue";
import {filter, take} from "rxjs";
import {useCore} from "~/core/core.service";
import {useDestroyable} from "~/core/util/composition-util";
import {ErrorCode} from "~/core/rpc/rpc-models";
import {useRegisterSW} from "virtual:pwa-register/vue";

const core = useCore();
const { autoUnsubscribe } = useDestroyable();

const defaultLayout = DashboardLayout;

const router = useRouter();

const firstLoadComplete: Ref<boolean> = ref(false);
const needReload: Ref<boolean> = ref(false);

const layout = computed(
    () => {
      // if there is no resolved route, then don't display a layout
      if (router.currentRoute.value.matched.length === 0) return null;
      // otherwise display the layout based on the route
      return router.currentRoute.value.meta.layout || defaultLayout;
    }
);

const checkForUpdates = () => {
  if (needRefresh.value) {
    updateServiceWorker();
  }
};

const serviceWorkerRegistration: Ref<any> = ref(null);
const forceReload: Ref<any> = ref(false);

const {
  offlineReady,
  needRefresh,
  updateServiceWorker,
} = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    serviceWorkerRegistration.value = r;
  },
})

watch(needRefresh, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    needReload.value = true;
    if (firstLoadComplete.value && forceReload.value) {
      checkForUpdates();
    }
  }
});

function reloadPage() {
  window.location.reload();
}

autoUnsubscribe(core.rpc.observeNextAppVersion().subscribe((nextVersion: string) => {
  // we're out-of-date, so we need to look for an opportunity to reload the page, such as during a page navigation
  if (serviceWorkerRegistration.value) serviceWorkerRegistration.value.update();
}));

autoUnsubscribe(core.rpc.observeErrors().pipe(filter((e: any) => e.code === ErrorCode.NotAuthenticated), take(1)).subscribe(() => {
  // anything we should do if a NotAuthenticated error makes it to the app. Should we redirect to the login screen?
  core.nav.root(core.nav.paths.login());
}));

autoUnsubscribe(core.rpc.observeForceUpdate().pipe(take(1)).subscribe(() => {
  // we know the app is broken and cannot be used until an update is acquired
  forceReload.value = true;
  router.push('/update')
  if (serviceWorkerRegistration.value) serviceWorkerRegistration.value.update();
}));

router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void) => {
  if (!firstLoadComplete.value) {
    firstLoadComplete.value = true;
  } else if (needReload.value) {
    // only perform page loads on subsequent navigations, not the very first page load
    checkForUpdates();
  }
});

</script>
