<template>
  <slot v-if="hasPermission"></slot>
</template>

<script setup lang="ts">
import {PropType, Ref, ref, watch} from "vue";
import {useCore} from "~/core/core.service";
import {parseToggles} from "~/core/toggles.service";

const core = useCore();

const props = defineProps({
  toggles: {
    type: String as PropType<string>,
    required: true,
    default: ''
  }
});

const hasPermission: Ref<boolean> = ref(false);

watch(() => props.toggles, (value, oldValue, onCleanup) => {
  core.toggles.checkToggles(parseToggles(props.toggles)).then((result) => {
    hasPermission.value = result;
  });
}, { deep: true, immediate: true });
</script>
<style>
</style>
