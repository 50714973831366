import {timer} from "rxjs";
import {cloneDeep, deepEquals} from "~/core/util/object-util";

export interface ThrottleCall {
  delay?: number;
  key: string;
  params: any;
  call: () => Promise<void>;
}

export class ThrottleService {
  private pendingCalls: Map<string, ThrottleCall> = new Map();

  constructor(
  ) {
  }

  throttleCall(call: ThrottleCall) {
    if (!this.pendingCalls.has(call.key)) {
      this.pendingCalls.set(call.key, call);
      const invoke = () => {
        call.call().then(() => {
          const pendingCall = this.pendingCalls.get(call.key);
          this.pendingCalls.delete(call.key);
          if (pendingCall && pendingCall !== call && !deepEquals(pendingCall.params, call.params)) {
            this.throttleCall(pendingCall);
          }
        }, (error) => {
          this.pendingCalls.delete(call.key);
        }).catch((error) => {
          this.pendingCalls.delete(call.key);
        });
      };
      if (call.delay) {
        timer(call.delay).subscribe(value => invoke());
      } else {
        invoke();
      }
    } else {
      this.pendingCalls.set(call.key, {
        key: call.key,
        call: call.call,
        params: cloneDeep(call.params),
      });
    }
  }
}
