import router from '~/router';
import {SessionService} from "./session.service";
import {PermissionService} from "./permission.service";
import {NavigationFailure, NavigationHookAfter, RouteLocationNormalized, RouteLocationRaw, Router} from "vue-router";
import {BehaviorSubject, Observable, takeWhile} from "rxjs";
import {TogglesService} from "~/core/toggles.service";
import {ToastService} from "~/core/toast.service";
import {Permission, SecurityContextType} from "~/core/rpc/rpc-models";
import {createAuthenticatedGuard, createNotAuthenticatedGuard, createPermissionGuard} from "~/core/navigation-guards";

export class NavigationService {
  private _activeNav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _activeRoute: BehaviorSubject<RouteLocationNormalized> = new BehaviorSubject<RouteLocationNormalized>(null);
  private router: Router;

  paths = {
    home: (): Promise<RouteLocationRaw> => { return this.homePath(); },
    login: (): RouteLocationRaw => { return "/login"; },
    logout: (): RouteLocationRaw => { return "/logout"; },
    profile: (): RouteLocationRaw => { return "/profile"; },
    dashboard: (): RouteLocationRaw => { return "/dashboard"; },
    contractorHub: (): RouteLocationRaw => { return "/contractor/construction-projects"; },
    adminDashboard: (): RouteLocationRaw => { return "/admin/dashboard"; },
    maintenance: (): RouteLocationRaw => { return "/maintenance"; },
  }

  constructor(
    private readonly permissions: PermissionService,
    private readonly toast: ToastService,
    private readonly toggles: TogglesService,
    private readonly session: SessionService,
  ) {
    this.router = router;
    this.router.beforeEach(createAuthenticatedGuard(this, session));
    this.router.beforeEach(createNotAuthenticatedGuard(this, session));
    this.router.beforeEach(createPermissionGuard(this, permissions));
    this.router.afterEach(this.createRouteTracker());
  }

  observeActiveRoute(): Observable<RouteLocationNormalized> {
    return this._activeRoute.asObservable();
  }

  back() {
    this.router.back();
  }

  refreshHome() {
    this.session.refreshSession().then(() => {
      this.home();
    });
  }

  externalNav(location: RouteLocationRaw) {
    this.session.isAuthenticated().then(authenticated => {
      if (authenticated) {
        this._activeNav.pipe(takeWhile(active => !!active, true)).subscribe((active) => {
          if (!active) {
            this.root(location);
          }
        });
      } else {
        // just don't navigate if not authenticated
      }
    });
  }

  maintenance() {
    this.root(this.paths.maintenance());
  }

  home(): void {
    this.paths.home().then((location) => {
      this.root(location);
    });
  }

  public replace(location: RouteLocationRaw) {
    this.router.replace(location);
  }

  public forward(location: RouteLocationRaw) {
    this.router.push(location);
  }

  public root(location: RouteLocationRaw) {
    this.router.push(location);
  }

  public updateQueryParam(paramName: string, paramValue: string) {
    // Get the current route
    const currentRoute = this.router.currentRoute.value;
    // Get the current query parameters
    const currentQuery = { ...currentRoute.query };
    // Update the specific parameter
    currentQuery[paramName] = paramValue;
    // Push the updated query to the route
    this.router.replace({ query: currentQuery });
  }

  private homePath(): Promise<RouteLocationRaw> {
    return new Promise((resolvePath) => {
      let checks: Array<() => Promise<boolean>> = [];
      const anyContextPermissionsChecker = (type: SecurityContextType, location: RouteLocationRaw): Promise<boolean> => {
        return new Promise((resolve) => {
          this.permissions.getContextPermissions(type).then((permissions) => {
            if (permissions.length > 0) {
              resolvePath(location);
              resolve(true);
            } else {
              resolve(false);
            }
          }, (error) => {
            resolve(false);
          });
        });
      };
      const hasPermissionChecker = (permission: Permission, location: RouteLocationRaw): Promise<boolean> => {
        return new Promise((resolve) => {
          this.permissions.evalPermissions(String(permission)).then((passed) => {
            console.log("HAS " + permission + " = " + passed);
            if (passed) {
              resolvePath(location);
              resolve(true);
            } else {
              resolve(false);
            }
          }, (error) => {
            resolve(false);
          });
        });
      };

      checks.push(() => {
        // authenticated check
        return new Promise((resolve) => {
          this.session.isAuthenticated().then(authenticated => {
            if (authenticated) {
              // authenticated, so continue to subsequent checks
              resolve(false);
            } else {
              // not authenticated, so need to login
              resolvePath(this.paths.login());
              resolve(true);
            }
          }, (reject) => {
            resolvePath(this.paths.login());
            resolve(true);
          })
        });
      });
      checks.push(() => hasPermissionChecker('unverified', '/register/verify'));
      checks.push(() => anyContextPermissionsChecker('GLOBAL_ADMIN', this.paths.adminDashboard()));
      checks.push(() => anyContextPermissionsChecker('CONSTRUCTION_PROJECT', this.paths.contractorHub()));
      checks.push(() => hasPermissionChecker('er_submitters_eps_all$', '/e-record/packages'));
      checks.push(() => anyContextPermissionsChecker('SELF', this.paths.profile()));

      checks = checks.reverse();
      const doCheck = () => {
        if (checks.length > 0) {
          checks.pop()().then((result) => {
            if (result) {
              // completed a check, so done
            } else {
              doCheck();
            }
          }, (error) => {
            doCheck();
          });
        } else {
          // no more checks, so done
        }
      };
      doCheck();
    });
  }

  private createRouteTracker(): NavigationHookAfter {
    return (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void) => {
      this._activeRoute.next(to);
    };
  }
}
